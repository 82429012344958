<template>
  <div class="d-flex justify-content-between align-items-center mb-5">
    <h4 v-if="showTitle" class="card-title">Mandate sources</h4>
    <select class="form-select ml-auto" @change="handleAccountChange" style="width: 200px">
      <option v-for="account in accounts" :key="account.account_card_id" :value="account.account_card_id">
        {{ account.bank_name }}-{{ account.account_number }}
      </option>
    </select>
  </div>
  <swiper :slides-per-view="1.5" :space-between="20">
    <swiper-slide>
      <div
          class="stat-widget d-flex flex-column rounded-lg py-4 px-4 bg-white mb-1"
          :class="sources.Direct_debit ? 'border-success' : ''"
      >
        <div class="d-flex justify-content-between align-items-center">
          <img src="@/assets/images/nibss-logo.png" alt="" height="40">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
          <p class="text-primary-dark mb-0 fw-500">NIBBS</p>
          <template v-if="sources.Direct_debit">
            <div v-if="current.IS_DD_DONE === '0'" class="badge bg-danger">Not done</div>
            <div v-if="current.IS_DD_DONE === '1'" class="badge bg-warning">Pending</div>
            <div v-if="current.IS_DD_DONE === '2'" class="badge bg-success">Done</div>
          </template>
          <div v-else class="badge bg-danger">Not active</div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div
          class="stat-widget d-flex flex-column rounded-lg py-4 px-4 bg-white"
          :class="sources.Card ? 'border-success' : ''"
      >
        <div class="d-flex justify-content-between align-items-center">
          <img src="@/assets/images/paystack.png" alt="" height="40">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
          <p class="text-primary-dark mb-0 fw-500">Card</p>
          <template v-if="sources.Card">
            <div v-if="current.IS_CARD_DONE === '0'" class="badge bg-danger">Not done</div>
            <div v-if="current.IS_CARD_DONE === '1'" class="badge bg-success">Done</div>
          </template>
          <div v-else class="badge bg-danger">Not active</div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div
          class="stat-widget d-flex flex-column rounded-lg py-4 px-4 bg-white"
          :class="sources.agents ? 'border-success' : ''"
      >
        <div class="d-flex justify-content-between align-items-center">
          <img src="@/assets/images/real-estate-agent.svg" alt="" height="40">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
          <p class="text-primary-dark mb-0 fw-500">Agents</p>
          <template v-if="sources.agents">
            <div v-if="current.IS_AGENT_DONE === '0'" class="badge bg-danger">Not done</div>
            <div v-if="current.IS_AGENT_DONE === '1'" class="badge bg-success">Done</div>
          </template>
          <div v-else class="badge bg-danger">Not active</div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div
          class="stat-widget d-flex flex-column rounded-lg py-4 px-4 bg-white"
          :class="sources?.remita ? 'border-success' : ''"
      >
        <div class="d-flex justify-content-between align-items-center">
          <img src="@/assets/images/remita-logo.svg" alt="" height="40">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
          <p class="text-primary-dark mb-0 fw-500">Remita</p>
          <template v-if="sources.remita">
            <div class="badge bg-danger">Not done</div>
          </template>
          <div v-else class="badge bg-danger">Not active</div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div
          class="stat-widget d-flex flex-column rounded-lg py-4 px-4 bg-white"
          :class="sources?.salary ? 'border-success' : ''"
      >
        <div class="d-flex justify-content-between align-items-center">
          <img src="@/assets/images/calendar.svg" alt="" height="40">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
          <p class="text-primary-dark mb-0 fw-500">Salary sources</p>
          <template v-if="sources.salary">
            <div class="badge bg-danger">Not done</div>
          </template>
          <div v-else class="badge bg-danger">Not active</div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: "MandateSources",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      sources: {},
      current: null
    }
  },
  props: {
    accounts: {
      type: Array,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.setCurrentAccount(this.accounts[0]);
  },
  methods: {
    handleAccountChange(e) {
      const account_card_id = e.target.value;
      const account = this.accounts.find(a => a.account_card_id === account_card_id);
      if (account) this.setCurrentAccount(account);
    },
    setCurrentAccount(account) {
      if (!account) return;
      this.current = account;
      this.sources = JSON.parse(account.channels) ?? {};
    }
  }
}
</script>
