<template>
  <div v-if="!success" class="my-auto d-flex flex-column p-5">
    <h4 class="mt-4 text-primary-dark">Setup mandate on this account?</h4>
    <ul class="list-group mt-4">
      <li class="list-group-item">{{ account.bank_name }}</li>
      <li class="list-group-item">{{ account.account_number }}</li>
    </ul>
    <div class="mt-4">
      <button @click="setupMandate" class="btn btn-primary px-3" :disabled="loading">
          <span v-if="loading" class="spinner-grow spinner-grow-sm me-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </span>
        Setup mandate
      </button>
    </div>
  </div>
  <div v-else class="my-auto d-flex flex-column align-items-center justify-content-center">
    <i class="ri-checkbox-circle-fill text-success" style="font-size: 4rem"/>
    <div class="mt-4 fs-6">
      Mandate request has been sent
    </div>
    <button @click="$emit('done')" class="btn btn-outline-danger btn-sm mt-4">
      Close
    </button>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";

export default {
  name: "RequestMandate",
  data: () => ({
    isRequestMandateVisible: false,
    loading: false,
    success: false
  }),
  props: {
    account: {
      type: Object,
      required: true
    },
  },
  emits: ['done'],
  created() {
    console.log(this.account);
  },
  methods: {
    async setupMandate() {
      const payload = {
        token: localStorage.getItem('token'),
        collection_id: this.$route.params.id,
        account_card_id: this.account.account_card_id
      };
      this.loading = true;
      try {
        const res = await this.$http.post(`collections/initiate/account`, payload);
        if (!res.data.status) createToast(res.data?.message || 'An error occurred, please try again', {
          type: 'danger', timeout: 5000
        }); else {
          this.success = true;
        }
      } catch (e) {
        createToast(e?.response.data?.message || 'An error occurred, please try again', { type: 'danger', timeout: 5000 });
      }
      this.loading = false;
    }
  }
}
</script>
