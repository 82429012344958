<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Accounts</h4>
    </div>
    <div class="card-body">
      <swiper :slides-per-view="1.5" :space-between="20">
        <swiper-slide v-for="account in accounts">
          <div class="stat-widget d-flex justify-content-between align-items-center rounded-lg py-4 px-4 bg-white mb-2">
            <div class="w-100">
              <p class="m-0">{{ account.bank_name }}</p>
              <div class="d-flex align-items-center justify-content-between w-100">
                <h4 class="m-0">{{ account.account_number }}</h4>
                <div v-if="account.IS_PRIMARY === '1'" class="badge bg-primary">Primary</div>
              </div>
              <button v-if="account.IS_PRIMARY !== '1'" @click="requestMandate(account)" class="btn btn-outline-secondary btn-sm mt-2">
                Request mandate
              </button>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <Modal :visible="isRequestMandateVisible" @close="isRequestMandateVisible = false">
    <RequestMandate :account="current" @done="isRequestMandateVisible = false"/>
  </Modal>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import Modal from "@/components/Modal";
import RequestMandate from "@/components/details/RequestMandate";

export default {
  name: "Accounts",
  components: { RequestMandate, Modal, Swiper, SwiperSlide },
  data: () => ({
    isRequestMandateVisible: false,
    current: null,
    loading: false,
    success: false
  }),
  props: {
    accounts: {
      type: Array,
      required: true
    },
  },
  methods: {
    requestMandate(account) {
      this.current = account;
      this.isRequestMandateVisible = true;
    },
  }
}
</script>
