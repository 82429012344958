<template>
  <div v-if="loading" class="position-relative mt-5 py-5 text-center fst-italic d-flex align-items-center justify-content-center">
    <div class="spinner-border spinner-border-sm me-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    Loading payments..
  </div>
  <div v-else-if="!payments.length" class="text-center text-muted mt-5 py-5">No payments</div>
  <div v-else class="table-responsive">
    <table class="table table-sm table-striped">
      <thead>
      <tr>
        <th>Date</th>
        <th>Amount</th>
        <th>Source</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="p in payments" :key="p.transaction_reference">
        <td>{{ p.date_paid }}</td>
        <td>{{ $filters.formatCurrency(p.amount_paid) }}</td>
        <td>{{ resolvePaymentSource(p.source) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Payments",
  props: {
    payments: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    resolvePaymentSource(source) {
      if (source === '0') return 'Backend';
      else if (source === '1') return 'Card';
      else if (source === '2') return 'Card';
      else if (source === '3') return 'Direct debit';
      else if (source === '4') return 'Agent';
      else return 'Unknown';
    }
  },
}
</script>
