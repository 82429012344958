<template>
  <div class="my-auto p-5">
    <VeeForm v-if="!success" :validation-schema="schema" v-slot="{ handleSubmit, isSubmitting }">
      <h4>Enter customer details</h4>
      <form @submit="handleSubmit($event, onSubmit)" class="mt-5">
        <Field v-slot="{ field, errorMessage }" name="phone">
          <div class="mb-4">
            <label for="phone" class="form-label">Customer phone number</label>
            <input v-bind="field" type="tel" class="form-control" id="phone" :disabled="isSubmitting"/>
            <div v-if="errorMessage" class="invalid-feedback d-flex">{{ errorMessage }}</div>
          </div>
        </Field>
        <Field v-slot="{ field, errorMessage }" name="name">
          <div class="mb-4">
            <label for="name" class="form-label">Customer full name</label>
            <input v-bind="field" type="text" class="form-control" id="name" :disabled="isSubmitting"/>
            <div v-if="errorMessage" class="invalid-feedback d-flex">{{ errorMessage }}</div>
          </div>
        </Field>
        <Field v-slot="{ field, errorMessage }" name="company">
          <div class="mb-4">
            <label for="company" class="form-label">Where does customer work?</label>
            <input v-bind="field" type="text" class="form-control" id="company" :disabled="isSubmitting"/>
            <div v-if="errorMessage" class="invalid-feedback d-flex">{{ errorMessage }}</div>
          </div>
        </Field>
        <button :disabled="isSubmitting" type="submit" class="btn btn-primary mt-4">
          <span v-if="isSubmitting" class="spinner-grow spinner-grow-sm me-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </span>
          Continue
        </button>
      </form>
    </VeeForm>
    <div v-else class="d-flex flex-column justify-content-center align-items-center text-center py-5 mx-auto" style="max-width: 400px">
      <i class="ri-checkbox-circle-fill text-success" style="font-size: 4rem"/>
      <div class="mt-4 mb-3 fs-6">
        Link has been sent to customer
      </div>
      <button class="btn btn-outline-primary btn-sm mt-4">Copy link</button>
    </div>
  </div>
</template>

<script>
import { object, string } from "yup";
import { markRaw } from "vue";

const schema = markRaw(object({
  phone: string().matches(/(^[0]\d{10}$)/).required().label('Phone number'),
  name: string().required().label('Full name'),
  company: string().required().label('Company name'),
}));

export default {
  name: "NewRecovery",
  data() {
    return {
      success: false,
      link: null,
      schema
    }
  },
  methods: {
    async onSubmit(v) {
      const { api_key } = this.$store.state.user;
      try {
        const res = await this.$http.post('https://whatsapp.creditclan.com/api/customer/generate/qr', v, {
          headers: { 'x-api-key': api_key }
        });
        this.link = res.data.data;
        this.success = true;
        this.$emit('done');
      } catch (e) {
        console.log({ e });
      }
    }
  }
}
</script>
