<template>
  <div class="sellup-wrapper">
    <div class="sellup-container left d-flex flex-column align-items-center justify-content-center">
      <div class="text-center mt-5">
        <img class=" sellup-img" src="https://omni.creditclan.com/assets/images/analytics.svg"/>
      </div>
      <br><br><br><br>
      <div class="text-center">
        <h5 class="text-white fs-4">Create an account and get started <br> with managing collections for your business</h5>
      </div>
    </div>
    <div class="sellup-container bg-white right">
      <div class="signup-form py-5">
        <img src="@/assets/images/cc-logo-md.png" width="60"/>
        <h4 class="card-title mt-4 fs-4">Create your account</h4>
        <form method="post" name="myform" class="signin_validate row g-3 mt-4" action="https://Creditclan-html.vercel.app/verify-email.html">
          <div class="col-12">
            <label class="form-label">Email</label>
            <input type="email" v-model="email" @blur="validateEmail" class="form-control" placeholder="hello@example.com">
            <span class="error-message"></span>
          </div>
          <div class="col-12 ">
            <label class="form-label">Phone</label>
            <vue-tel-input v-model="phone" mode="international"></vue-tel-input>
            <span class="phone-error-message"></span>
          </div>
          <div class="col-12 ">
            <label class="form-label">Account type</label>
            <div>
              <div class="form-check form-check-inline">
                <input v-model="accountType" class="form-check-input" type="radio" id="individual" value="Individual">
                <label class="form-check-label" for="individual">Individual</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="accountType" class="form-check-input" type="radio" id="business" value="Business">
                <label class="form-check-label" for="business">Business</label>
              </div>
            </div>
          </div>
          <div class="col-12 position-relative" v-if="accountType === 'Business'">
            <label class="form-label">Business Type</label>
            <select class="form-control position-relative" v-model="businessType">
              <option value="Lenders">Lenders</option>
              <option value="Others">Others</option>
            </select>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </div>
          <div class="col-12 " v-if="accountType === 'Business' && businessType === 'Others'">
            <label class="form-label">Specify business type</label>
            <input type="text" v-model="businessTypeCustom" class="form-control" placeholder="specity business type">
            <span class="error-message"></span>
          </div>
          <div class="col-12 " v-if="accountType === 'Individual'">
            <label class="form-label">Full name</label>
            <input type="text" v-model="legal_name" class="form-control" placeholder="Creditclan Technologies Limited"
                   @blur="validateFullName">
            <span class="error-message"></span>
          </div>
          <div class="col-12 " v-if="accountType === 'Business'">
            <label class="form-label">Business Name</label>
            <input type="text" v-model="legal_name" class="form-control" placeholder="Creditclan Technologies Limited"
                   @blur="validateBusinessName">
            <span class="error-message"></span>
          </div>
          <div class="col-12">
            <div class="position-relative">
              <label class="form-label">Password</label>
              <input type="password" v-model="password" class="form-control" placeholder="Password" name="password" @blur="validatePassword"
                     ref="pass">
              <i class="fa eye-icon cursor-pointer" aria-hidden="true" :class="[showEye ? 'fa-eye-slash' : 'fa-eye']"
                 @click="toggleEyeIcon('pass')"></i>
              <span class="error-message"></span>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">
                I certify that I am 18 years of age or older, and agree to the
                <a href="#" class="text-primary">User Agreement</a> and
                <a href="#" class="text-primary">Privacy Policy</a>.
              </label>
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" class="btn btn-primary mt-2 d-flex align-items-center" @click="signup" :disabled="loading">
              <span v-if="loading" class="spinner-grow spinner-grow-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              Create account
            </button>
          </div>
        </form>
        <p class="mt-3 mb-0">
          Already have an account?
          <router-link :to="{name: 'login'}" class="text-primary">Sign in here</router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  components: {
    PulseLoader
  },
  data() {
    return {
      legal_name: null,
      phone: null,
      business_name: null,
      email: null,
      password: null,
      submitStatus: null,
      showEye: false,
      formIsValid: false,
      loading: false,
      accountType: 'Individual',
      businessType: 'Lenders',
      businessTypeCustom: null
    }
  },
  mounted() {
    const phoneErr = document.querySelector('.phone-error-message')
    document.querySelector('.vti__input').addEventListener('blur', (e) => {
      if (!e.target.value) {
        phoneErr.style.color = 'red'
        phoneErr.textContent = 'Enter phone number'
        document.querySelector('.vue-tel-input ').classList.add('error-border')
      } else {
        phoneErr.textContent = ''
        document.querySelector('.vue-tel-input ').classList.remove('error-border')
      }
    })
  },
  methods: {
    signup(e) {
      e.preventDefault();
      const phone = document.querySelector('.vti__input').value
      if (!this.email) {
        return createToast('email is required', { type: 'danger' })
      } else if (!this.validEmail(this.email)) {
        return createToast('Invalid email format', { type: 'danger' })
      }
      if (!phone) {
        return createToast('Phone is required', { type: 'danger' })
      }
      if (this.accountType === 'Individual') {
        if (!this.legal_name) {
          return createToast('Full name is required', { type: 'danger' })
        }
      }
      if (this.accountType === 'Business') {
        if (!this.legal_name) {
          return createToast('Business name is required', { type: 'danger' })
        }
        if (this.accountType === 'Business' && this.businessType === 'Others' && !this.businessTypeCustom) {
          return createToast('Specify business type', { type: 'danger' })
        }
      }
      if (!this.password) {
        return createToast('Password is required', { type: 'danger' })
      }
      let data = {
        legal_name: this.legal_name,
        phone: document.querySelector('.vti__input').value,
        business_name: this.legal_name,
        email: this.email,
        password: this.password,
      }
      this.loading = true
      this.$http.post(`onboard`, data)
          .then(async response => {
            this.loading = false;
            if (response.data.status === false) return createToast(response.data.message, { type: 'danger' })
            else createToast(response.data.message, { type: 'success' })
            localStorage.setItem('token', response.data.token);
            await this.$router.push(`${ this.$route.query.from || '/' }`);
          })
          .catch(() => {
            this.loading = false;
          });
    },
    validateName(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Name is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
    },
    validateEmail(e) {
      const email = e.target.value
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Email is required'
        e.target.classList.add('error-border')
      } else {
        if (!this.validEmail(email)) {
          errorMessage.textContent = 'Invalid email format'
          e.target.classList.add('error-border')
        } else {
          errorMessage.textContent = ''
          e.target.classList.remove('error-border')
        }
      }
    },
    validatePassword(e) {
      const errorMessage = e.target.parentNode.children[3]
      if (!e.target.value) {
        errorMessage.textContent = 'Password is required'
        e.target.classList.add('error-border')
        this.formIsValid = false
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
        this.formIsValid = true
      }
    },
    validateFullName(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Full name is required'
        e.target.classList.add('error-border')
        this.formIsValid = false
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
        this.formIsValid = true
      }
    },

    validateBusinessName(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Business name is required'
        e.target.classList.add('error-border')
        this.formIsValid = false
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
        this.formIsValid = true
      }
    },
    validEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
    toggleEyeIcon() {
      this.showEye = !this.showEye
      this.$refs.pass.type === 'text'
          ? this.$refs.pass.type = 'password'
          : this.$refs.pass.type = 'text'
    },
  }
}
</script>
