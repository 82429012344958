<template>
  <div v-if="loading" class="position-relative mt-5 py-5 text-center fst-italic d-flex align-items-center justify-content-center">
    <div class="spinner-border spinner-border-sm me-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    Loading timeline..
  </div>
  <div v-else-if="!timeline.length" class="text-center text-muted mt-5 py-5">No activity</div>
  <div v-else class="timeline">
    <div v-for="item in timeline" class="timeline-container primary">
      <div class="timeline-icon">
        <i class="far fa-grin-wink"></i>
      </div>
      <div class="timeline-body">
        <p class="m-0">{{ item.TIMELINE_ACTIVITY }}</p>
        <p class="timeline-subtitle m-0 mt-1">{{ item.DATE_ADDED }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Timeline",
  data: () => ({
    timeline: [],
    loading: false
  }),
  created() {
    this.getTimeline();
  },
  methods: {
    async getTimeline() {
      const payload = {
        token: localStorage.getItem('token'),
        collection_id: this.$route.params.id,
      };
      this.loading = true;
      const res = await this.$http.post(`collection/timeline`, payload);
      this.timeline = res.data?.data ?? [];
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
// colors
$primary: #1d8cf8;
$secondary: #4f537b;
$info: #11cdef;
$success: #00bf9a;
$warning: #ff8d72;
$danger: #fd5d93;

// timeline variables
$timeline-before-bg: #222a42;
$timeline-body-bg: #f5f5f5;
$timeline-body-round: 6px;
$timeline-body-shadow: none;

// typography
p {
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  opacity: .8;
}

// timeline style
.timeline {
  position: relative;
  padding-left: 2rem;
  margin: 0 0 0 5px;
  color: #333;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: $timeline-body-bg;
  }

  .timeline-container {
    position: relative;
    margin-bottom: 1.2rem;;

    .timeline-icon {
      position: absolute;
      left: -38px;
      top: 22px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      text-align: center;
      font-size: 2rem;
      background: $secondary;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .timeline-body {
      background: $timeline-body-bg;
      border-radius: $timeline-body-round;
      padding: 20px 20px 15px;
      box-shadow: $timeline-body-shadow;
      color: #000;

      &:before {
        content: '';
        background: inherit;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        left: -10px;
        transform: rotate(45deg);
        border-radius: 0 0 0 2px;
      }

      .timeline-subtitle {
        font-weight: 300;
        font-style: italic;
        opacity: .6;
        margin-top: 2px;
        font-size: 11px;
      }
    }
  }
}


.author {
  font-family: inherit;
  padding: 3em;
  text-align: center;
  width: 100%;
  color: white;

  a:link,
  a:visited {
    color: white;

    &:hover {
      text-decoration: none;
    }
  }

  .btn:link,
  .btn:visited {
    margin-top: 1em;
    text-decoration: none;
    display: inline-block;
    font-family: inherit;
    font-weight: 100;
    color: white;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: black;
    padding: 1.5em 2rem;
    border-radius: 1em;
    transition: .5s all;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten(black, 10%);
    }
  }
}
</style>
