<template>
  <div>
    <div @click="$emit('close')" class="custom-modal-overlay" :class="{visible: visible }"></div>
    <div class="custom-modal" :class="{ visible: visible }">
      <template v-if="visible">
        <slot/>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible(v) {
      if (v) document.body.style.overflow = 'hidden';
      else document.body.style.overflowY = 'auto';
    }
  }
}
</script>

<style scoped lang="scss">
.custom-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: all .2s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.custom-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 550px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .1);
  border-radius: 5px 0 0 5px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000;
  transition: all .2s ease-in-out;
  transform: translateX(100%);
  background: #fff;
  display: flex;
  flex-direction: column;

  &.visible {
    transform: translateX(0);
  }
}
</style>
