<template>
  <MasterLayout>
    <div class="container">
      <div class="page-title">
        <div class="row align-items-center justify-content-between">
          <div class="col-xl-4">
            <div class="page-title-content">
              <h3>Create Collection</h3>
              <p class="mb-2">Welcome Creditclan Create Collection page</p>
            </div>
          </div>
          <div class="col-auto">

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Create Collection </h4>

            </div>
            <div class="card-body">
              <form class="invoice-form">
                <form>
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="mb-3"><label class="form-label">Customer Name</label><input type="email"
                                                                                              class="form-control" v-model="full_name"
                                                                                              placeholder="Ebenezer Igbinoba">
                      </div>
                      <div class="mb-3"><label class="form-label">Customer Phone</label><input
                          type="email" class="form-control" v-model="phone" placeholder="08163763753 ">
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="mb-3"><label class="form-label">Customer Email</label><input
                          type="email" class="form-control" v-model="email" placeholder="eben@gmail.com"></div>
                      <div class="mb-3"><label class="form-label">Customer Address</label><input
                          type="email" class="form-control" v-model="home_address" placeholder="45 Oladokun street, Ketu Lagos"></div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="mb-3"><label class="form-label">Amount</label><input type="email"
                                                                                       class="form-control" v-model="amount" placeholder="6000">
                      </div>
                      <div class="mb-3"><label class="form-label">Duration</label><input
                          type="email" class="form-control" v-model="duration" placeholder="2 ">
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="mb-3"><label class="form-label">Start Date</label>
                        <Datepicker
                            v-model="start_date"
                            :lowerLimit="new Date()"
                            inputFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="mb-3 d-flex-space"><label class="form-label">Card</label><input class="pull-right" type="checkbox" :checked="card"
                                                                                                  v-model="card" placeholder="Jonaed Bogdadi">
                      </div>
                      <div class="mb-3 d-flex-space"><label class="form-label">Direct debit</label>
                        <input type="checkbox" class="pull-right" :checked="direct_debit" v-model="direct_debit">
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="mb-3 d-flex-space"><label class="form-label">Agent</label><input
                          type="checkbox" class="pull-right" :checked="agent" v-model="agent"></div>

                    </div>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-primary" @click="createCollection"
                            :disabled="isButtonDisabled"
                            :style="[isButtonDisabled ? {cursor: 'not-allowed'} : null]">
                      <span v-if="!isButtonDisabled">Save</span>
                      <pulse-loader size="15px" v-if="isButtonDisabled"></pulse-loader>
                    </button>
                  </div>
                </form>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MasterLayout>
</template>
<script>

import MasterLayout from '@/views/Auth/MasterLayout'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Datepicker from "vue3-datepicker"

export default {
  components: {
    MasterLayout,
    PulseLoader,
    Datepicker
  },
  data() {
    return {
      isButtonDisabled: false,
      customer_id: "",
      full_name: "",
      email: "",
      phone: "",
      home_address: "",
      state: "",
      lga: "",
      amount: "",
      frequency: "",
      duration: "",
      start_date: new Date(),
      formattedDate: null,
      channel: "",
      account_number: "",
      bank_id: "",
      account_name: "",
      card: true,
      direct_debit: false,
      agent: true
    }
  },
  created() {
    this.formattedDate = this.formatDate(new Date())
  },
  mounted() {
    document.querySelector('.v3dp__datepicker').children[0].classList.add('form-control')
  },
  watch: {
    start_date(date) {
      this.formattedDate = this.formatDate(date)
    }
  },
  methods: {
    formatDate(dateStr) {
      let d = dateStr, month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [day, month, year].join('-');
    },
    validateEmail(email) {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    createCollection(e) {
      e.preventDefault();
      if (!this.phone) {
        createToast('Phone is required', {
          type: 'danger'
        })
        return
      } else {
        if (this.phone.length !== 11) {
          createToast('Phone should contain 11 digits! ', {
            type: 'danger'
          })
          return
        }
      }
      if (!this.email) {
        createToast('Email is required', {
          type: 'danger',
          timeout: 5000
        })
        return
      } else {
        if (!this.validateEmail(this.email)) {
          createToast('Invalid email format', {
            type: 'danger',
            timeout: 5000
          })
          return
        }
      }
      const payload = {
        full_name: this.full_name,
        email: this.email,
        phone: this.phone,
        home_address: this.home_address,
        amount: this.amount,
        duration: this.duration,
        start_date: this.formattedDate,
        card: this.card,
        direct_debit: this.direct_debit,
        agent: this.agent
      }
      const data = {
        token: localStorage.getItem('token'),
        data: payload
      }
      this.isButtonDisabled = true
      this.$http.post(`collections/create`, data)
          .then(() => {
            this.isButtonDisabled = false
            this.showLoader = false
            this.$router.push('/collections')
            createToast('Collection created successfully', {
              type: 'success'
            })
          })
    }
  }
}
</script>
