<template>
  <div>
    <div class="text-center" v-if="!schedule || schedule.length < 1">
      <h6>No schedules recorded!</h6>
    </div>
    <div v-if="schedule && schedule.length > 0">
      <table class="table table-sm table-striped">
        <thead>
        <tr>
          <th>Date</th>
          <th>Amount paid</th>
          <th>Balance</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(s,i) in schedule" :key="i">
          <td>{{ s.repayment_date }}</td>
          <td>{{ $filters.formatCurrency(s.how_much_paid) }}</td>
          <td>{{ $filters.formatCurrency(s.how_much_remaining) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Schedule",
  props: {
    schedule: {
      type: Array,
      required: true
    }
  },
}
</script>
