<template>
  <div class="main-wrapper">
    <AppBar/>
    <Sidebar/>
    <div class="content-body">
      <slot>
        <router-view/>
      </slot>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import AppBar from "@/components/AppBar";

export default {
  components: { AppBar, Sidebar, },
}
</script>
