<template>
  <div class="d-flex align-items-end justify-content-between bg-light border-bottom" style="margin-bottom: 10px; padding: 3rem 2rem 0">
    <ul class="nav nav-tabs-custom">
      <li class="nav-item">
        <a @click="changeTab('existingCustomer')" class="nav-link" :class="{active: tab === 'existingCustomer'}" href="#">
          Update Message
        </a>
      </li>
      
    </ul>
  </div>

  <div class="collection-wrapper flex-grow-1 overflow-y-auto">
    <form class="row g-3">
      <div class="d-flex align-items-center mb-4">
        
        <h3 class="m-0 ms-3">Select message recipients</h3>
      </div>
      <div class="d-flex flex-column">
        <label class="collection-channel mb-3 pb-4 border-bottom d-flex align-items-center me-5">
          <input type="checkbox" class="form-check-input me-3" v-model="channels.Card" :checked="channels.card"/>
          
          <span class="text-dark font-bold">Self</span>
        </label>
        <label class="collection-channel mb-3 pb-4 border-bottom d-flex align-items-center">
          <input type="checkbox" class="form-check-input me-3" v-model="channels.Direct_debit" :checked="channels.Direct_debit"/>
          
          <span class="text-dark font-bold">Family</span>
        </label>
        <label class="collection-channel mb-3 pb-4 border-bottom me-5 d-flex align-items-center">
          <input type="checkbox" class="form-check-input me-3" v-model="channels.agents" :checked="channels.agents"/>
          
          <span class="text-dark font-bold">Friends</span>
        </label> 
        <label class="collection-channel mb-3 pb-4 border-bottom me-5 d-flex align-items-center">
          <input type="checkbox" class="form-check-input me-3" v-model="channels.agents" :checked="channels.agents"/>
          
          <span class="text-dark font-bold">Religious</span>
        </label> 
      </div>
      <div class="mt-5">
        <button class="btn btn-primary" @click="createCollection" :disabled="isButtonDisabled">
          <span v-if="isButtonDisabled" class="spinner-grow spinner-grow-sm me-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </span>
          Save
        </button>
      </div>
    </form>


    <div
        v-if="stage === 'success'" style="max-width: 400px"
        class="d-flex flex-column justify-content-center align-items-center text-center py-5 mx-auto"
    >
      <i class="ri-checkbox-circle-fill text-success" style="font-size: 4rem"/>
      <div class="mt-4 fs-6">
        Email and Whatsapp message have been sent
      </div>
      <button class="btn btn-outline-secondary btn-sm mt-4">
        Share link
      </button>
    </div>
  </div>
</template>

<script>
import States from "@/assets/Js/states.json";
import moment from "moment";
import { createToast } from "mosha-vue-toastify";
import TableLoader from "@/components/TableLoader";
import Datepicker from "vue3-datepicker";

export default {
  name: "UpdateMessage",
  props: {
    messageopen: {
      default: ""
    }
  },
  components: { TableLoader, Datepicker, },
  data() {
    return {
      states: States,
      tab: 'existingCustomer',
      token: localStorage.getItem('token'),
      customers: [],
      start: 0,
      page: 1,
      PrevCustomers: [],
      NextCustomers: [],
      showUsersLoader: false,
      search: '',
      isButtonDisabled: false,
      phone: null,
      email: null,
      full_name: null,
      name: null,
      customerEmail: null,
      stage: null,
      amount: null,
      interval: 'weekly',
      interval_date: new Date(),
      customInterval: null,
      formattedIntervalDate: null,
      start_date: new Date(),
      formattedStartDate: null,
      end_date: null,
      formattedEndDate: null,
      address: null,
      landmark: null,
      state: 'select state',
      selectedCustomer: {},
      duration: null,
      channelsObj: {},
      payOutAccounts: [],
      analysis: [],
      lender_account_id: 'select account',
      account_name: null,
      account_number: null,
      repaymentAnalysis: false,
      channels: {
        Card: false,
        Direct_debit: false,
        agents: false,
        Ippis: false,
        Remita: false,
        Offline: false
      },
      searchTimeout: null,
      user: null,
    }
  },
  created() {
    this.getPayoutAccounts();
    this.formattedStartDate = this.formatDate(new Date())
    this.formattedEndDate = this.formatDate(new Date())
    this.formattedIntervalDate = this.formatDate(new Date())
  },
  methods: {
    autoFillDateInWeeks() {
      const end_date = moment(this.start_date).add((this.duration * 7), 'days').format("YYYY-M-D")
      this.end_date = new Date(end_date)
    },
    autoFillDateInMonths() {
      const end_date = moment(this.start_date).add((this.duration), 'months').format("YYYY-M-D")
      this.end_date = new Date(end_date)
    },
    formatDate(dateStr) {
      let d = dateStr, month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let todaysDate = [day, month, year].join('-');
      return todaysDate;
    },
    goBack() {
      if (this.stage === 'schedule') {
        this.stage = 'customerInfo'
      }

      if (this.stage === 'bankInfo') {
        this.stage = 'schedule'
      }

      if (this.stage === 'collectionChannel') {
        this.stage = 'bankInfo'
      }

      if (this.stage === 'address') {
        this.stage = 'collectionChannel'
      }

      if (this.stage === 'customerInfo' && this.tab === 'existingCustomer') {
        this.stage = null
      }
    },
    changeStage($event, stage) {
      $event.preventDefault()
      if (stage === 'schedule' || stage === 'customerInfo') {
        if (!this.name) {
          createToast('Name is required', { type: 'danger', });
          return
        }
        if (!this.customerEmail) {
          createToast('Email is required', { type: 'danger', });
          return
        } else {
          if (!this.validEmail(this.customerEmail)) {
            createToast('Invalid email format', { type: 'danger', });
            return
          }
        }
        if (!this.phone) {
          createToast('Phone is required', { type: 'danger' });
          return
        } else {
          if (this.phone.length !== 11) {
            createToast('Phone should contain 11 digits! ', { type: 'danger' });
            return
          }
        }
      } else if (stage === 'bankInfo') {
        if (!this.amount) {
          createToast('Amount is required', { type: 'danger', });
          return
        }

        if (!this.duration) {
          createToast('Duration is required', { type: 'danger', });
          return
        }

        if (this.interval === 'customInterval') {
          if (parseInt(this.customInterval) < 1 || parseInt(this.customInterval) > 100) {
            createToast('Custom interval should be between 1 & 100', { type: 'danger', });
            return false
          }
        }
      } else if (stage === 'collectionChannel') {
        if (this.lender_account_id === 'select account') {
          createToast('Please select an account to proceed', { type: 'danger', });
          return
        }
        if (!this.account_number) {
          createToast('Account number is required', { type: 'danger', });
          return
        }
        if (!this.account_name) {
          createToast('Account name is required', { type: 'danger', });
          return
        }
      } else if (stage === 'address') {
        this.validateChannels()
      }
      this.stage = stage
    },
    selectCustomer(customer) {
      this.selectedCustomer = customer
      this.customerEmail = customer.EMAIL
      this.phone = customer.PHONE
      this.name = customer.LEGAL_NAME
      this.stage = 'customerInfo'
    },
    validateEmail(e) {
      const email = e.target.value
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Email is required'
        e.target.classList.add('error-border')
      } else {
        if (!this.validEmail(email)) {
          errorMessage.textContent = 'Invalid email format'
          e.target.classList.add('error-border')
        } else {
          errorMessage.textContent = ''
          e.target.classList.remove('error-border')
        }
      }
    },
    validEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
    validateName(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Name is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
    },
    validateAmount(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Amount is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
    },
    validateAddress(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Address field is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
    },
    validateAccountNumber(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Account number field is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
      const payload = {
        "account_number": this.account_number,
        "bank_code": this.lender_account_id
      }
      const data = {
        token: localStorage.getItem('token'),
        data: payload
      }
      this.isButtonDisabled = true
      this.$http.post(`validate/account`, data)
          .then((response) => {
            this.isButtonDisabled = false
            this.account_name = response.data.data.account_name
          })
    },
    validateAccountName(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Account name field is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
    },
    validateState(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'State field is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
    },
    validateLandmark(e) {
      const errorMessage = e.target.parentNode.children[2]
      if (!e.target.value) {
        errorMessage.textContent = 'Landmark field is required'
        e.target.classList.add('error-border')
      } else {
        errorMessage.textContent = ''
        e.target.classList.remove('error-border')
      }
    },
    validateChannels() {
      const channelKeys = Object.keys(this.channels)
      let channels = {}
      for (let channel of channelKeys) {
        if (this.channels[channel]) {
          channels[channel] = this.channels[channel]
        }
      }
      this.channelsObj = channels
      if (Object.keys(this.channelsObj).length < 1) {
        createToast('Select a channel to proceed', {
          type: 'danger',
        })
        return false
      }
      return true
    },
    processChannels(event) {
      event.preventDefault()
      const validatedChannels = this.validateChannels()
      if (validatedChannels) {
        if (this.channels.agents) {
          this.stage = 'address'
          return
        } else {
          this.stage = 'analysis';
        }
      }
    },
    processAnalysis() {
      this.saveCollection();
    },
    saveCollection() {
      const payload = {
        "customer_id": this.selectedCustomer ? this.selectedCustomer.PEOPLE_ID : '',
        "full_name": this.name,
        "email": this.customerEmail,
        "phone": this.phone,
        "home_address": this.address,
        "state": this.state === 'select state' ? "" : this.state,
        "lga": "",
        "amount": parseFloat(this.amount),
        "frequency": this.interval === 'customInterval' ? this.customInterval : this.interval,
        "duration": parseInt(this.duration),
        "start_date": this.formattedStartDate,
        "end_date": this.formattedEndDate,
        "channels": this.channelsObj,
        "account_number": this.account_number,
        "bank_id": this.lender_account_id,
        "account_name": this.account_name,
        "repayment_analysis": this.repaymentAnalysis
      }
      const data = {
        token: localStorage.getItem('token'),
        data: payload
      }
      this.isButtonDisabled = true
      this.$http.post(`collections/create`, data)
          .then(() => {
            this.isButtonDisabled = false
            this.showLoader = false
            this.stage = 'success'
            this.name = null
            this.customerEmail = null
            this.phone = null
            this.address = null
            this.state = null
            this.amount = null
            this.interval = 'weekly'
            this.duration = null
            this.channelsObj = {}
          })
    },
    createCollection(e) {
      console.log({ e });
      e.preventDefault();
      console.log(this.state, this.address, this.landmark);
      if (this.channels.agents) {
        if (!this.address) {
          createToast('Address is required', {
            type: 'danger',
          })
          return
        }
        if (!this.landmark) {
          createToast('Landmark is required', {
            type: 'danger',
          })
          return
        }
        if (this.state === 'select state') {
          createToast('State is required', {
            type: 'danger',
          })
          return
        }
      }
      if (this.state === 'select state') this.state = null
      this.stage = 'analysis';
    },
    nextPage() {
      this.start += 21
      this.page = this.page + 1
    },
    prevPage() {
      this.start -= 21
      this.page = this.page - 1
    },
    changeTab(tab) {
      this.tab = tab
      if (this.tab === 'newCustomer') {
        this.selectedCustomer = {}
        this.customerEmail = null
        this.phone = null
        this.name = null
        this.stage = 'customerInfo'
      } else {
        this.stage = null
      }
    },
    searchCustomer() {
      const data = {
        token: this.token,
        search: this.search
      }
      this.showUsersLoader = true
      this.$http.post(`customer/search`, data)
          .then(response => {
            this.customers = response.data.customers
            this.showUsersLoader = false
          })
    },
    getCustomers() {
      const data = {
        token: this.token,
        start: this.start
      }
      this.showUsersLoader = true
      this.$http.post(`customers`, data)
          .then(response => {
            this.showUsersLoader = false
            this.customers = response.data.customers
            this.getNextSet()
            if (this.start > 20) {
              this.getPrevSet()
            }
          })
    },
    getNextSet() {
      const data = {
        token: this.token,
        start: this.start + 20
      }
      this.$http.post(`customers`, data)
          .then(response => {
            this.NextCustomers = response.data.customers
          })
    },
    getPrevSet() {
      const data = {
        token: this.token,
        start: this.start - 20
      }
      this.$http.post(`customers`, data)
          .then(response => {
            this.PrevCustomers = response.data.customers
          })
    },
    getPayoutAccounts() {
      const data = {
        token: this.token
      }
      this.showLoader = true
      this.$http.post(`banks`, data)
          .then((res) => {
            this.showLoader = false
            this.payOutAccounts = res.data.data
          })
    },
  },
  watch: {
    page(page) {
      this.getCustomers()
    },
    start_date() {
      if (!this.duration) return
      this.interval === 'weekly' ? this.autoFillDateInWeeks() : this.autoFillDateInMonths()
    },
    duration() {
      this.interval === 'weekly' ? this.autoFillDateInWeeks() : this.autoFillDateInMonths()
    },
    interval() {
      this.interval === 'weekly' ? this.autoFillDateInWeeks() : this.autoFillDateInMonths()
    },
    end_date(date) {
      this.formattedEndDate = this.formatDate(date)
    },
    interval_date(date) {
      this.formattedIntervalDate = this.formatDate(date)
    },
    search(searchText) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      if (!searchText) {
        this.customers = [];
      } else {
        this.searchTimeout = setTimeout(() => {
          this.searchCustomer();
        }, 1000);
      }
    }
  },
  computed: {
    filteredCustomers() {
      return this.customers && this.customers.length > 0
          ? this.customers.filter(customer => customer.LEGAL_NAME.toLowerCase().includes(this.search.toLowerCase()))
          : []
    },
  }
}
</script>
