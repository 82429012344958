<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <div class="header-content">
            <div class="header-left">
              <div class="brand-logo">
                <a class="mini-logo" href="#"><img src="https://creditclan.com/pub/images/logo-home.png" alt="" width="40"></a>
              </div>
            </div>
            <div class="header-right">
              <button @click="toggleSlideOut()" class="btn btn-outline-primary d-flex align-items-center">
                <i class="ri ri-add-circle-line me-2"></i> New collection
              </button>
              <div v-if="user" class="dropdown ms-3">
                <button class="user icon-button active cursor-pointer" data-bs-toggle="dropdown" id="dropdownMenuButton1">
                  <span><i class="ri-user-line"></i></span>
                </button>
                <ul class="dropdown-menu mt-2" aria-labelledby="dropdownMenuButton1">
                  <li class="d-flex align-items-center border-bottom">
                    <div class="user d-flex align-items-center px-3">
                      <span class="thumb me-2" style="width: 40px"><img class="w-100" src="@/assets/img/profile/3.png" alt=""></span>
                      <div class="user-info">
                        <h5 class="m-0 p-0" style="line-height: 1">{{ user.legal_name }}</h5>
                        <small class="m-0">{{ user.email }}</small>
                      </div>
                    </div>
                  </li>
                  <li class="mt-2">
                    <router-link to="/settings" class="dropdown-item">Settings</router-link>
                  </li>
                  <li><a @click="logout" class="dropdown-item" href="#">Logout</a></li>
                </ul>
              </div>
              <div v-else class="spinner-grow spinner-grow-sm text-primary ms-3" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :visible="newCollectionVisible" @close="newCollectionVisible = false">
    <NewCollection v-if="newCollectionVisible"/>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import NewCollection from "@/components/NewCollection";

export default {
  name: "AppBar",
  components: { NewCollection, Modal },
  data() {
    return {
      newCollectionVisible: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    toggleSlideOut() {
      this.newCollectionVisible = !this.newCollectionVisible;
    },
    logout() {
      localStorage.clear();
      this.$store.commit('clear');
      this.$router.push('/');
    }
  }
}
</script>
