<template>
  <div class="p-5">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="card-title">All connections</h4>
      <div class="ml-auto d-flex align-items-center">
        <button
            @click="$emit('refresh')"
            class="btn btn-light btn-sm d-flex align-items-center justify-content-center rounded-circle"
            style="width: 35px; height: 35px"
        >
          <i class="ri-refresh-line"></i>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 d-flex align-items-center">
      <input v-model="query" type="search" class="form-control rounded-pill" placeholder="Search by phone or name.."/>
    </div>
    <div v-if="loading" class="mt-4">
      <div class="position-relative">
        <div class="card-loader" style="height: 40px"></div>
      </div>
      <div class="position-relative mt-2">
        <div class="card-loader" style="height: 40px"></div>
      </div>
    </div>
    <ul v-else class="list-group mt-4">
      <li
          v-for="t in items" :key="t.transactionId" @click="$emit('details', t.transactionId)"
          class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
      >
        <div>
          <div>{{ t.name }} - {{ t.phone }}</div>
          <div class="text-muted">{{ t.company }}</div>
        </div>
        <div v-if="t.qrStatus === 0" class="d-flex align-items-center">
          <div style="width: 10px; height: 10px;" class="rounded-circle bg-warning me-2"></div>
          <span class="text-warning">Not connected</span>
        </div>
        <div v-if="t.qrStatus === 1" class="d-flex align-items-center">
          <div style="width: 10px; height: 10px;" class="rounded-circle bg-success me-2"></div>
          <span class="text-success">Connected</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RecoveryConnections",
  props: {
    connections: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      query: '',
    }
  },
  computed: {
    items() {
      if (this.query) return this.connections.filter(t => t.name.includes(this.query) || t.phone.includes(this.query));
      else return this.connections;
    }
  }
}
</script>
