import { createStore } from "vuex"

const store = createStore({
	strict: true,
	state: {
		user: null,
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		clear(state) {
			state.user = null;
		}
	},
})

export default store
