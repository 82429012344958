<template>
  <router-view/>
</template>

<script>
import "@/assets/sass/styles.scss"

export default {
  name: 'App',
}
</script>
