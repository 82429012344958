<template>
  <MasterLayout>
    <div class="container">
      <template v-if="!ready">
        <div class="position-relative" style="width: 200px; height: 30px">
          <div class="card-loader h-100"></div>
        </div>
        <div class="row mt-4">
          <div class="col-xl-3" v-for="i in 4" :key="i">
            <div class="position-relative">
              <div class="card-loader small"></div>
            </div>
          </div>
        </div>
        <div class="position-relative mt-4">
          <div class="card-loader small"></div>
        </div>
      </template>
      <template v-else>
        <h3 class="page-title">Collections portfolio</h3>
        <div class="row">
          <div class="col-xl-4 col-sm-6">
            <div class="stat-widget d-flex align-items-center bg-white">
              <div class="widget-icon me-3 bg-primary"><i class="ri-file-copy-2-line"></i></div>
              <div class="widget-content">
                <h3>{{ summary?.total?.count }}</h3>
                <p>Total Pending</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-sm-6">
            <div class="stat-widget d-flex align-items-center bg-white">
              <div class="widget-icon me-3 bg-success"><i class="ri-file-list-3-line"></i></div>
              <div class="widget-content">
                <h3>{{ summary?.active?.count }}</h3>
                <p>Active</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-sm-6">
            <div class="stat-widget d-flex align-items-center bg-white">
              <div class="widget-icon me-3 bg-warning"><i class="ri-file-paper-line"></i></div>
              <div class="widget-content">
                <h3>{{ summary?.active?.count }}</h3>
                <p>Closed</p>
              </div>
            </div>
          </div>
          <!--          <div class="col-xl-3 col-sm-6">-->
          <!--            <div class="stat-widget d-flex align-items-center bg-white">-->
          <!--              <div class="widget-icon me-3 bg-danger"><i class="ri-file-paper-2-line"></i></div>-->
          <!--              <div class="widget-content">-->
          <!--                <h3>{{ summary?.cancelled?.count }}</h3>-->
          <!--                <p>Cancelled</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <div class="mb-4 d-flex align-items-center">
              <input
                  v-model="search_string" @keypress.enter="handleSearch()" type="search" class="form-control form-control-lg rounded-pill"
                  placeholder="Search customers.." :disabled="loading" style="height: 45px"
              />
              <button
                  @click="cancelSearch()" class="btn btn-danger rounded-pill d-flex align-items-center justify-content-center ms-2"
                  v-if="searching" :disabled="!search_string" style="width: 40px; height: 40px"
              >
                <i class="ri-close-line fs-6 mr-2"></i>
              </button>
              <button
                  @click="handleSearch()" class="btn btn-primary rounded-pill d-flex align-items-center justify-content-center ms-2"
                  v-else :disabled="loading" style="width: 40px; height: 40px"
              >
                <i class="ri-search-line fs-6 mr-2"></i>
              </button>
            </div>

            <ul>
              <li class="d-flex justify-content-between align-items-center pb-4" v-for="(collectionPort,i) in collections" :key="i">
                <div class="d-flex align-items-center flex-grow-1">
                  <div class="budget-icon me-3 rounded-circle overflow-hidden">
                    <img :src="require('@/assets/img/user.png')" alt="" width="25">
                  </div>
                  <div class="budget-info flex-grow-1 me-3">
                    <div class="d-flex justify-content-between mb-1 w-100">
                      <div class="d-flex flex-column w-100">
                        <h6 class="m-0">
                          {{ collectionPort.LEGAL_NAME }}
                        </h6>
                        <div class="d-flex justify-content-between align-items-center w-100">
                          <p style="font-size: .85rem" class="text-muted m-0">
                            {{ $filters.formatCurrency(collectionPort.REQUEST_PRINCIPAL) }} |
                            {{
                              collectionPort.LOAN_DURATION === 'Months' ? 'monthly' :
                                  collectionPort.LOAN_DURATION === 'Days' ? 'daily' : collectionPort.LOAN_DURATION
                            }}
                          </p>
                          <p class="m-0"><strong>{{ collectionPort.PercentPaid }} </strong> / 100</p>
                        </div>
                        <div class="progress mt-1" style="height: 8px">
                          <div class="progress-bar" role="progressbar" :style="[{width: `${collectionPort.PercentPaid}%`}]"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <router-link :to="`/portfolio/${collectionPort.REQUEST_ID}`" class="btn btn-outline-primary btn-sm">View</router-link>
                </div>
              </li>
            </ul>

            <div v-if="loading">
              <div class="position-relative" style="height: 30px">
                <div class="card-loader h-100"></div>
              </div>
              <div class="position-relative mt-3" style="height: 30px">
                <div class="card-loader h-100"></div>
              </div>
              <div class="position-relative mt-3" style="height: 30px">
                <div class="card-loader h-100"></div>
              </div>
            </div>

            <div v-if="!pagination.completed && !loading" class="text-center mt-4">
              <button @click="loadMore()" class="btn btn-light btn-sm px-3 rounded-pill" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
                Load more
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </MasterLayout>
</template>
<script>

import MasterLayout from '@/views/Auth/MasterLayout'
import TableLoader from "@/components/TableLoader";

export default {
  name: 'CollectionsPortfolio',
  components: { MasterLayout, TableLoader },
  data() {
    return {
      summary: null,
      collections: [],
      start: 0,
      loading: false,
      pagination: {
        start: 0,
        count: 20,
        completed: false,
      },
      ready: false,
      searching: false,
      search_string: ''
    }
  },
  mounted() {
    this.getCollectionSummary(this.pagination.start)
  },
  methods: {
    nextPage() {
      this.start += 20
      this.page = this.page + 1
    },
    prevPage() {
      this.start -= 20
      this.page = this.page - 1
    },
    async getCollectionSummary(start) {
      const data = { token: localStorage.getItem('token'), start };
      this.loading = true;
      const response = await this.$http.post(`collections`, data)
      this.ready = true;
      this.loading = false;
      this.summary = response.data.summary
      this.pagination.completed = response.data.complete;
      this.pagination.start = response.data.start;
      this.collections = [...this.collections, ...response.data.loans];
    },
    handleSearch() {
      if (!this.search_string) return;
      if (!this.searching) {
        this.collections = [];
        this.searching = true;
      }
      this.searchCollection(this.pagination.start);
    },
    cancelSearch() {
      this.collections = [];
      this.searching = false;
      this.search_string = '';
      this.getCollectionSummary();
    },
    async searchCollection(start) {
      this.loading = true;
      const data = { token: localStorage.getItem('token'), start, search_string: this.search_string };
      const response = await this.$http.post(`collections`, data);
      this.pagination.completed = response.data.complete;
      this.pagination.start = response.data.start;
      this.collections = [...this.collections, ...response.data.loans];
      this.loading = false;
    },
    loadMore() {
      this.getCollectionSummary(this.pagination.start);
    },
  },
  computed: {
    page() {
      return this.pagination.start / this.pagination.count;
    }
  }
}
</script>
