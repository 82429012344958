<template>
  <MasterLayout>
    <div v-if="showLoader" class="container">
      <div class="position-relative" style="width: 200px; height: 60px">
        <div class="card-loader h-100"></div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="position-relative">
            <div class="card-loader" style="height: 230px"></div>
          </div>
          <div class="position-relative mt-4">
            <div class="card-loader" style="height: 200px"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative">
            <div class="card-loader" style="height: 200px"></div>
          </div>
          <div class="position-relative mt-4">
            <div class="card-loader" style="height: 200px"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="details" class="container">
      <div class="d-flex align-items-center mb-4">
        <button
            @click="$router.back()" class="btn btn-outline-secondary rounded-pill d-flex align-items-center justify-content-center"
            style="width: 30px; height: 30px"
        >
          <i class="ri-arrow-left-line fs-6"></i>
        </button>
        <div class="d-flex align-items-center ms-3">
          <div>
            <img
                :src="summary?.nin_details?.photo ? `data:image/jpeg;base64,${summary.nin_details.photo}` : (details.FILE_NAME || require('@/assets/img/user.png'))"
                class="rounded-circle overflow-hidden bg-light" style="width: 50px; height: 50px"
            />
          </div>
          <h3 class="page-title m-0 ms-3">
            {{ details.LEGAL_NAME }}
            <small>
              {{ $filters.formatCurrency(details.REQUEST_PRINCIPAL) }} <i class="mx-1">•</i>
              {{ details.DURATION }} {{ details.LOAN_DURATION }}
            </small>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <!-- 1 -->
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Details</h4>
              <div class="dropdown">
                <button
                    class="btn btn-outline-secondary btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                  <i class="ri-more-2-fill"/>
                </button>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <div class="dropdown-item cursor-pointer" @click="modals.skip = true">
                      <i class="ri-skip-forward-line mr-3"></i>Skip Interval
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="unpaid-content">
                <ul>
                  <li>
                    <p class="mb-0">Name</p>
                    <h5 class="mb-0" v-if="!showLoader">{{ details.LEGAL_NAME }}</h5>
                  </li>
                  <li>
                    <p class="mb-0">Amount</p>
                    <h5 class="mb-0" v-if="!showLoader">{{ $filters.formatCurrency(schedules[0]?.term_repayment) }}</h5>
                  </li>
                  <li>
                    <p class="mb-0">Frequency</p>
                    <h5 class="mb-0" v-if="!showLoader">{{ details.FREQUENCY }}</h5>
                  </li>
                  <li>
                    <p class="mb-0">No. of installments</p>
                    <h5 class="mb-0">{{ details.DURATION }}</h5>
                  </li>
                  <li>
                    <p class="mb-0">Start date</p>
                    <h5 class="mb-0">{{ startDate }}</h5>
                  </li>
                  <li v-if="details?.LOAN_STATUS > 2">
                    <p class="mb-0">Time spent</p>
                    <h5 class="mb-0">{{ details.TIME_SPENT }}</h5>
                  </li>
                  <li v-if="details?.LOAN_STATUS > 2">
                    <p class="mb-0">Amount Remaining</p>
                    <h5 class="mb-0">{{ $filters.formatCurrency(details.remaining) }}</h5>
                  </li>
                  <li v-if="details?.LOAN_STATUS > 2">
                    <p class="mb-0">Source</p>
                    <h5 class="mb-0">{{
                        !details.REPAYMENT_MODE ? 'N/A' : details.REPAYMENT_MODE == "2" ?
                            'Online' : details.REPAYMENT_MODE == "3" ? ' Direct Debit Mandate' : details.REPAYMENT_MODE == "6" ?
                                'Agents' : null
                      }}</h5>
                  </li>
                  <li v-if="details?.LOAN_STATUS > 2">
                    <p class="mb-0">Percentage paid</p>
                    <h5 class="mb-0">{{ details.PercentPaid }} / 100</h5>
                  </li>
                  <li class="d-flex align-items-center">
                    <p class="mb-0">Status</p>
                    <div class="text-end">
                      <div>{{ details.channels_activated }}/{{ details.total_channels }}</div>
                      <div class="progress mt-1" style="height: 7px; width: 100px;">
                        <div class="progress-bar" role="progressbar" :style="[{width: `${progress}%`}]"></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="d-flex mt-5">
                <button @click="cancelCollection" class="btn btn-outline-danger btn-sm">Cancel</button>
                <button @click="modals.confirmActivate = true" class="btn btn-success btn-sm ms-2">
                  Activate
                </button>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <Accounts :accounts="accounts"/>
        </div>
        <div class="col-lg-6 d-flex flex-column">
          <!-- 1 -->
          <div class="card">
            <div class="card-body">
              <MandateSources :accounts="accounts" show-title/>
            </div>
          </div>
          <!-- 2 -->
          <div class="card flex-grow-1">
            <div class="card-body d-flex flex-column">
              <h4 class="card-title">Should I give this loan?</h4>
              <h1 v-if="loan_check_passed < 1" class="mt-2 p-0 text-danger" style="line-height: 1">No</h1>
              <h1 v-else-if="loan_check_passed === 1" class="mt-2 p-0 text-warning" style="line-height: 1">Probably</h1>
              <h1 v-else-if="loan_check_passed > 1" class="mt-2 p-0 text-success" style="line-height: 1">Yes</h1>

              <div class="mt-8 d-flex justify-content-end mt-auto">
                <ul class="list-group w-100" style="max-width: 300px">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    {{ $filters.formatCurrency(monthly_amount) }}
                    <i v-if="loan_check.average_monthly_income_passed" class="fa fa-check-circle text-success"></i>
                    <i v-else class="fa fa-times-circle text-danger"></i>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    {{ details.LOAN_DURATION?.toLowerCase().match(/week/) ? 'Weekly' : '24th monthly' }}
                    <i v-if="loan_check.twenty_fourth_monthly_passed" class="fa fa-check-circle text-success"></i>
                    <i v-else class="fa fa-times-circle text-danger"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="analysis" class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Deep analytics</h4>
            </div>
            <div class="card-body">
              <swiper :slides-per-view="3.5" :space-between="20">
                <swiper-slide v-if="summary?.nin_details">
                  <div class="card p-4 h-100">
                    <h6 class="text-muted">Identity</h6>
                    <div class="d-flex justify-content-start mt-2">
                      <img :src="`data:image/jpeg;base64,${summary.nin_details.photo}`" class="rounded-circle bg-light"
                           style="width: 60px; height: 60px"/>
                    </div>
                    <div class="mt-3 d-flex w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100">
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Full name</span>
                          <span class="fw-bold">{{ summary.nin_details.surname }} {{ summary.nin_details.firstname }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Date of birth</span>
                          <span class="fw-bold">{{ summary.nin_details.birthdate }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="card p-4 h-100">
                    <h6 class="text-muted m-0">Statement borrowing history</h6>
                    <div class="mt-3 d-flex w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100">
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Average monthly repayment</span>
                          <span class="fw-bold">
                            {{ $filters.formatCurrency(+analysis.highlight.total_loans.replaceAll(',', '') / +analysis.highlight.no_month) }}
                          </span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Amount disbursement</span>
                          <span class="fw-bold">{{ $filters.formatCurrency(analysis.highlight.total_loans_disbursed) }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Lenders found</span>
                          <span class="fw-bold">{{ analysis.highlight.lender_list?.length ? 'Yes' : 'No' }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="card p-4 h-100">
                    <h6 class="text-muted m-0">Cashflow Highlight</h6>
                    <div class="mt-3 d-flex w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100">
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Best cashflow</span>
                          <span class="fw-bold">
                            {{ analysis.highlight.highest_deposit_week ? `Week ${ analysis.highlight.highest_deposit_week }` : 'N/A' }}
                          </span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Low cashflow</span>
                          <span class="fw-bold">
                            {{ analysis.highlight.lowest_deposit_week ? `Week ${ analysis.highlight.lowest_deposit_week }` : 'N/A' }}
                          </span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Avg daily balance</span>
                          <span class="fw-bold">{{ analysis.highlight.average_daily_balance }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Constant salary and date?</span>
                          <span class="fw-bold">{{ analysis.highlight.constant_salary }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="card p-4 h-100">
                    <h6 class="text-muted m-0">Habits</h6>
                    <div class="mt-3 d-flex w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100">
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Sweeper</span>
                          <span class="fw-bold">{{ analysis.highlight.percent_sweeper === 0 ? 'No' : 'Yes' }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Bounced cheques</span>
                          <span class="fw-bold">{{ analysis.bounce_cheque ? 'Yes' : 'No' }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Habitual borrower</span>
                          <span class="fw-bold">{{ analysis.is_borrower ? 'Yes' : 'No' }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Habitual gambler</span>
                          <span class="fw-bold">{{ analysis.is_gambler ? 'Yes' : 'No' }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Religious giver</span>
                          <span class="fw-bold">{{ analysis.is_religion ? 'Yes' : 'No' }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="card p-4 h-100">
                    <h6 class="text-muted m-0">Account activity</h6>
                    <div class="mt-3 d-flex w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100">
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Weekly withdrawal</span>
                          <span class="fw-bold">{{ analysis.highlight.weekly_percent_withdraw }}%</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Weekly deposit</span>
                          <span class="fw-bold">{{ analysis.highlight.weekly_percent_deposit }}%</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Monthly withdrawal</span>
                          <span class="fw-bold">{{ analysis.highlight.monthly_percent_withdraw }}%</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Monthly deposit</span>
                          <span class="fw-bold">{{ analysis.highlight.monthly_percent_deposit }}%</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="card p-4 h-100">
                    <h6 class="text-muted m-0">Ratios</h6>
                    <div class="mt-3 d-flex w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100">
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Withdrawal to Deposit</span>
                          <span class="fw-bold">{{ analysis.highlight.withdrawal_to_deposit_ratio }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Floating cash to deposit</span>
                          <span class="fw-bold">
                            {{ $filters.roundToDp((analysis.highlight.total_cashflow / analysis.highlight.total_deposit) * 100, 2) }}
                          </span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Debt to deposit</span>
                          <span class="fw-bold">
                            {{ $filters.roundToDp((analysis.highlight.total_withdrawal / analysis.highlight.total_deposit) * 100, 2) }}
                          </span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Debt repayment to deposit</span>
                          <span class="fw-bold">
                            {{ $filters.roundToDp((analysis.highlight.total_loans_repaid / analysis.highlight.total_deposit) * 100, 2) }}
                          </span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Debt repayment to salary</span>
                          <span class="fw-bold">
                            {{ $filters.roundToDp((analysis.highlight.total_loans_repaid / analysis.highlight.total_salary) * 100, 2) }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="card p-4 h-100">
                    <h6 class="text-muted m-0">Deposit amount</h6>
                    <div class="mt-3 d-flex w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100">
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span>Less than 10k</span>
                          <span class="fw-bold">{{ analysis.highlight.ten_k }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Less than 100k</span>
                          <span class="fw-bold">{{ analysis.highlight.hundred_k }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Less than 1M</span>
                          <span class="fw-bold">{{ analysis.highlight.one_m }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Less than 10M</span>
                          <span class="fw-bold">{{ analysis.highlight.ten_m }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Less than 100M</span>
                          <span class="fw-bold">{{ analysis.highlight.hundred_m }}</span>
                        </li>
                        <li class="list-group-item bg-transparent px-0 d-flex justify-content-between align-items-center">
                          <span class="text-grey">Over 100M</span>
                          <span class="fw-bold">{{ analysis.highlight.over_hundred_m }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>

              <div class="card p-4 mt-4">
                <h6 class="text-muted mb-3 border-bottom pb-3">Monthly summary</h6>
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                    <tr>
                      <td>Month</td>
                      <td>Days</td>
                      <td>Deposit</td>
                      <td>Withdrawal</td>
                      <td>Salary</td>
                      <td>Benefits</td>
                      <td>Loan</td>
                      <td>Betting</td>
                      <td>Utility</td>
                      <td>Payday</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in analysis.monthly_analytics">
                      <td>{{ item.new_month }}</td>
                      <td>{{ item.day }}</td>
                      <td>{{ $filters.formatCurrency(item.amount) }}</td>
                      <td>{{ $filters.formatCurrency(item.withdraw) }}</td>
                      <td>{{ $filters.formatCurrency(item.salary) }}</td>
                      <td>{{ $filters.formatCurrency(item.benefit) }}</td>
                      <td>{{ $filters.formatCurrency(item.loan) }}</td>
                      <td>{{ $filters.formatCurrency(item.gamble) }}</td>
                      <td>{{ $filters.formatCurrency(item.utility) }}</td>
                      <td>{{ item.payday || 0 }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MasterLayout>

  <Modal :visible="modals.confirmActivate" @close="modals.confirmActivate = false">
    <ActivateRequest :request="details" @close="modals.confirmActivate = false"/>
  </Modal>

  <Modal :visible="modals.skip" @close="modals.skip = false">
    <form action="#" class="my-auto p-5">
      <div class="row g-3">
        <div class="col-12 mb-3">
          <label class="form-label">No of Interval</label>
          <input type="text" class="form-control" placeholder="" v-model="interval">
        </div>
        <div class="col-12 col-12 mb-3">
          <button
              class="btn btn-primary" @click="skip" :disabled="isButtonDisabled"
              :style="[isButtonDisabled ? {cursor: 'not-allowed'} : null]"
          >
            <span v-if="!isButtonDisabled">Save</span>
            <pulse-loader size="15px" v-if="isButtonDisabled"></pulse-loader>
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import MasterLayout from '@/views/Auth/MasterLayout'
import TableLoader from "@/components/TableLoader";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Paginator from '@/components/Paginator'
import { Carousel, Slide } from 'vue3-carousel';
import { Swiper, SwiperSlide } from 'swiper/vue';
import banks from "../../lib/banks";
import Accounts from "../../components/details/Accounts";
import MandateSources from "../../components/details/MandateSources";
import { getWeekOfMonth } from "date-fns";
import Modal from "@/components/Modal";
import ActivateRequest from "@/components/details/ActivateRequest";

export default {
  components: {
    ActivateRequest,
    Modal,
    MandateSources,
    Accounts,
    MasterLayout,
    TableLoader,
    PulseLoader,
    Paginator,
    Carousel,
    Slide,
    Swiper, SwiperSlide
  },
  data() {
    return {
      isButtonDisabled: false,
      collections: [],
      collectionSummary: null,
      token: localStorage.getItem('token'),
      showLoader: true,
      tab: 'summary',
      details: null,
      analysis: null,
      summary: {},
      interval: 0,
      paginatedSchedules: [],
      paginatedTransactions: [],
      pages: [],
      pageSize: 5,
      totalPages: 1,
      currentPage: 1,
      tableCount: 0,
      modals: {
        skip: false,
        confirmActivate: false
      }
    }
  },
  async created() {
    await this.getCollectionDetails();
    if (this.details) await this.getAnalysisResult();
  },
  methods: {
    getBankName(bank_code) {
      return banks.find(bank => bank.bank_code === bank_code)?.name;
    },
    getPageSize(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1
      if (this.tab === 'schedule') {
        this.totalPages = Math.ceil(this.schedules.length / this.pageSize)
        this.tableCount = this.schedules.length
        this.paginatedSchedules = this.paginate(this.schedules)
      } else {
        this.totalPages = Math.ceil(this.transactions.length / this.pageSize)
        this.tableCount = this.transactions.length
        this.paginatedTransactions = this.paginate(this.transactions)
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.paginatedSchedules = this.paginate(this.schedules)
    },
    paginate(data) {
      let numberOfPages = Math.ceil(data.length / this.pageSize);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
      let pageSize = this.pageSize;
      let from = (this.currentPage * pageSize) - pageSize;
      let to = (this.currentPage * pageSize);
      return data.slice(from, to);
    },
    async getCollectionDetails() {
      const data = {
        token: this.token,
        collection_id: this.$route.params.id
      }
      const response = await this.$http.post(`collection/details`, data)
      this.showLoader = false
      this.details = response.data.data;
      if (this.details?.LOAN_STATUS < 3) this.tab = 'status';
      this.paginatedTransactions = this.paginate(this.transactions)
      this.summary = response.data.summary
    },
    async getAnalysisResult() {
      const transaction_id = this.details?.attachment?.ATTACHMENT_ANALYSIS;
      if (!transaction_id) return;
      const res = await this.axios.get(`https://app.bankstatement.ai/analytics/status/credible/${ transaction_id }`)
      this.analysis = res.data.analysis;
      console.log(this.analysis);
    },
    changeTab(tab) {
      this.tab = tab
      if (this.tab === 'schedule') {
        this.totalPages = Math.ceil(this.schedules.length / this.pageSize)
        this.tableCount = this.schedules.length
        this.paginatedSchedules = this.paginate(this.schedules)
      } else {
        this.totalPages = Math.ceil(this.transactions.length / this.pageSize)
        this.tableCount = this.transactions.length
        this.paginatedTransactions = this.paginate(this.transactions)
      }
    },
    cancelCollection() {
      this.tab = 'summary'
      const confirm = window.confirm('Are you sure you want to cancel this collection?')
      if (confirm) {
        const data = {
          token: localStorage.getItem('token'),
          collection_id: this.$route.params.id
        }
        this.$http.post(`collection/cancel`, data).then(() => {
          this.$router.replace('/requests');
        })
      }
    },
    checkStatus() {
      this.tab = 'summary'
      const confirm = window.confirm('Are you sure you want to check status?')
      if (confirm) {
        const data = {
          token: localStorage.getItem('token'),
          collection_id: this.$route.query.collection_id
        }
        this.$http.post(`collection/status`, data).then((res) => {
          createToast(res.data.message, { type: 'success' })
        })
      }
    },
    skip(e) {
      e.preventDefault();
      const confirm = window.confirm('Are you sure you want to skip this collection?')
      if (confirm) {
        const data = {
          token: localStorage.getItem('token'),
          collection_id: this.$route.query.collection_id,
          number: this.interval
        }
        this.isButtonDisabled = true
        this.$http.post(`collection/skip`, data)
            .then((res) => {
              this.isButtonDisabled = false
              createToast(res.data.message, {
                type: 'success'
              })
            })
      }
    },
  },

  computed: {
    accounts() {
      return this.details?.accounts ?? [];
    },
    sources() {
      return this.details?.GUARANTOR_REQUESTS ?? {};
    },
    schedules() {
      return this.details?.schedule ?? []
    },
    transactions() {
      return this.details?.chart_account_transactions ?? []
    },
    startDate() {
      return this.details?.schedule[0]?.repayment_date ?? 'N/A'
    },
    monthly_amount() {
      return this.details?.schedule[0]?.term_repayment ?? 0
    },
    loan_check() {
      const { LOAN_DURATION } = this.details ?? {};
      if (!LOAN_DURATION) return {};
      const { average_monthly_deposit, highest_deposit_week, weekly_percent_deposit } = this.analysis?.highlight ?? {};
      const week = getWeekOfMonth(new Date(this.startDate));
      let twenty_fourth_monthly_passed = false;
      if (LOAN_DURATION.toLowerCase().match(/week/)) {
        if (+weekly_percent_deposit >= 25) twenty_fourth_monthly_passed = true;
      } else if (LOAN_DURATION.toLowerCase().match(/month/)) {
        twenty_fourth_monthly_passed = week === highest_deposit_week;
      }
      return {
        average_monthly_income_passed: +average_monthly_deposit > +this.monthly_amount,
        twenty_fourth_monthly_passed,
      }
    },
    loan_check_passed() {
      return Object.keys(this.loan_check).filter(key => this.loan_check[key]).length;
    },
    progress() {
      return (+this.details.channels_activated / +this.details.total_channels) * 100;
    }
  }
}
</script>
