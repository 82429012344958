<template>
    <div>
         <table v-show="showLoader">
            <tbody>
                <tr v-for="(t,i) in 8" :key="i">
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['showLoader'],
    data() {
        return {
            tableRows: 5
        }
    }
}
</script>