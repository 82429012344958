<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Accounts</h4>
    </div>
    <div class="card-body">
      <swiper :slides-per-view="1.5" :space-between="20">
        <swiper-slide v-for="account in accounts">
          <div class="stat-widget d-flex flex-column justify-content-between rounded-lg py-4 px-4 bg-white mb-2">
            <p class="m-0">{{ account.bank_name }}</p>
            <h4 class="m-0">{{ account.account_number }}</h4>
            <div class="flex">
              <button
                  @click="charge(account)" class="btn btn-outline-secondary btn-xs mt-1"
                  :disabled="charging === account.account_card_id"
              >
                <span v-if="charging === account.account_card_id" class="spinner-grow spinner-grow-sm me-2" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
                Charge
              </button>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>

  <Modal :visible="modals.options" @close="modals.options = false">
    <div class="p-5">
      <div class="card bg-light d-flex flex-column justify-content-between rounded-lg py-4 px-4 mb-4">
        <p class="m-0">{{ current.bank_name }}</p>
        <div class="m-0 fs-5 fw-bold">{{ current.account_number }}</div>
      </div>
      <div v-if="current.IS_CARD_DONE !== '1'" class="alert alert-danger mb-4">
        No card attached to this account
      </div>
      <div @click="registerPayment()" class="card d-flex flex-row align-items-center justify-content-between mb-3 p-4 cursor-pointer">
        Register payment
        <i class="ri-arrow-right-line fs-5"></i>
      </div>
      <div
          @click="chargeCard()" v-if="current.IS_CARD_DONE === '1'"
          class="card d-flex flex-row align-items-center justify-content-between mb-3 p-4 cursor-pointer"
      >
        Charge card
        <i class="ri-arrow-right-line fs-5"></i>
      </div>
    </div>
  </Modal>

  <Modal :visible="modals.charge" @close="modals.charge = false">
    <ChargeCard :account="current" @done="modals.charge = false"/>
  </Modal>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import Modal from "@/components/Modal";
import ChargeCard from "@/components/details/ChargeCard";

export default {
  name: "PortfolioAccounts",
  components: { ChargeCard, Modal, Swiper, SwiperSlide },
  props: {
    accounts: {
      type: Array,
      required: true
    }
  },
  emits: ['register'],
  data: () => ({
    current: null,
    modals: {
      charge: false,
      options: false
    },
    charging: '',
  }),
  methods: {
    charge(account) {
      this.current = account;
      this.modals.options = true;
    },
    chargeCard() {
      this.modals.options = false;
      this.modals.charge = true;
    },
    registerPayment() {
      this.modals.options = false;
      this.$emit('register');
    },
  }
}
</script>
