import axios from "axios";

const http = axios.create({
	baseURL: 'https://api-collections.creditclan.com'
});

http.interceptors.request.use((config) => {
	const token = localStorage.getItem('token');
	if (token && !config.url.startsWith('http')) config.data = { ...config.data, token };
	return config;
}, (err) => {
	console.log('Axios error', err);
});

export default http;
