<template>
  <MasterLayout>
    <div class="container">
      <h3 class="page-title">Transfers</h3>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">New Transfer</h4>
        </div>
        <div class="card-body">
          <form action="#">
            <div class="row g-3">
              <div class="col-4 mb-3">
                <label class="form-label">Account</label>
                <select class="form-control" v-model="lender_account_id">
                  <option value="select account" disabled>select account</option>
                  <option
                      v-for="(payOutAccount) in payOutAccounts" :key="payOutAccount.LENDER_ACCOUNT_ID"
                      :value="payOutAccount.LENDER_ACCOUNT_ID">{{ payOutAccount.ACCOUNT_NAME }} ({{ payOutAccount.ACCOUNT_NUMBER }})
                  </option>
                </select>
              </div>
              <div class="col-4 mb-3">
                <label class="form-label">Amount</label>
                <input type="text" class="form-control" placeholder="" v-model="amount" @change="formatAmount">
              </div>
              <div class="col-4 mb-3">
                <label class="form-label">OTP</label>
                <input type="text" v-model="otp" class="form-control" placeholder="">
              </div>

              <div class="col-12 col-12">
                <button
                    class="btn btn-primary btn-sm me-3" @click="initiateTransfer" :disabled="isButtonDisabled"
                    :style="[isButtonDisabled ? {cursor: 'not-allowed'} : null]"
                >
                  <span v-if="!isButtonDisabled">Initiate</span>
                  <pulse-loader size="15px" v-if="isButtonDisabled"></pulse-loader>
                </button>
                <button
                    class="btn btn-outline-primary btn-sm" @click="validateTransfer" :disabled="isButton2Disabled"
                    :style="[isButton2Disabled ? {cursor: 'not-allowed'} : null]"
                >
                  <span v-if="!isButton2Disabled">Validate</span>
                  <pulse-loader size="15px" v-if="isButton2Disabled"></pulse-loader>
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
      <div v-if="!showLoader" class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Amount</th>
                <th>Date</th>
                <th>Account</th>
                <th>Transaction Reference</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(transfer,i) in transfers" :key="i">
                <td>
                  {{ transfer.amount }}
                </td>
                <td> {{ transfer.transaction_date }}</td>
                <td>{{ transfer.account_name }}</td>
                <td>{{ transfer.account_name }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="!showLoader" class="pagination-icon py-2 mt-4">
        <div class="mr-3 cursor-pointer" v-if="this.PrevTransfers && this.PrevTransfers.length > 1" @click="prevPage">
          <i class="fa fa-angle-double-left " aria-hidden="true" title="Previous page"></i>
        </div>
        <div class="mr-3 page-number d-flex align-items-center justify-content-center" title="Previous page">
          {{ page }}
        </div>
        <div class="cursor-pointer" @click="nextPage" v-if="this.NextTransfers && this.NextTransfers.length > 1">
          <i class="fa fa-angle-double-right pagination-arrow" aria-hidden="true" title="Next page"></i>
        </div>
      </div>
      <div v-if="showLoader" class="col-xl-12 mt-4">
        <div class="position-relative">
          <div class="card-loader small"></div>
        </div>
      </div>
    </div>
  </MasterLayout>
</template>
<script>

import MasterLayout from '@/views/Auth/MasterLayout'
import TableLoader from "@/components/TableLoader";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  components: {
    MasterLayout,
    TableLoader,
    PulseLoader
  },
  watch: {
    page() {
      this.getTransfers()
    }
  },
  data() {
    return {
      start: 0,
      token: localStorage.getItem('token'),
      showLoader: true,
      transfers: [],
      page: 1,
      NextTransfers: [],
      PrevTransfers: [],
      payOutAccounts: [],
      lender_account_id: 'select account',
      amount: null,
      otp: null,
      isButtonDisabled: false,
      txn_ref: null,
      isButton2Disabled: false
    }
  },
  mounted() {
    this.getTransfers()
    this.getPayoutAccounts()
  },
  methods: {
    validateTransfer(e) {
      e.preventDefault()
      if (this.lender_account_id == 'select account') {
        createToast('select an account', {
          type: 'danger',
          timeout: 5000
        })
        return
      }
      if (!this.amount) {
        createToast('Enter amount', {
          type: 'danger',
          timeout: 5000
        })
        return
      }

      if (!this.otp) {
        createToast('Enter OTP', {
          type: 'danger',
          timeout: 5000
        })
        return
      }

      const data = {
        token: localStorage.getItem('token'),
        txn_ref: this.txn_ref,
        otp: this.otp
      }
      this.isButton2Disabled = true
      this.$http.post(`transfers/validate`, data)
          .then((res) => {
            this.txn_ref = res.data.txn_ref
            this.isButton2Disabled = false
            createToast(res.data.data, {
              type: res.data.status == false ? 'danger' : 'success'
            })
          })
    },
    initiateTransfer(e) {
      e.preventDefault()
      if (this.lender_account_id == 'select account') {
        createToast('select an account', {
          type: 'danger',
          timeout: 5000
        })
        return
      }
      if (!this.amount) {
        createToast('Enter amount', {
          type: 'danger',
          timeout: 5000
        })
        return
      }

      const data = {
        token: localStorage.getItem('token'),
        amount: this.amount,
        lender_account_id: this.lender_account_id
      }
      this.isButtonDisabled = true
      this.$http.post(`transfers/initiate`, data)
          .then((res) => {
            this.txn_ref = res.data.txn_ref
            this.isButtonDisabled = false
            createToast(res.data.data, {
              type: res.data.status == false ? 'danger' : 'success'
            })
          })
    },
    formatAmount(e) {
      this.amount = this.$filters.formatCurrency(e.target.value);
    },
    nextPage() {
      this.start += 21
      this.page = this.page + 1
    },
    prevPage() {
      this.start -= 21
      this.page = this.page - 1
    },
    getTransfers() {
      const data = {
        token: this.token,
        start: this.start
      }
      this.showLoader = true
      this.$http.post(`transfers/list`, data)
          .then(response => {
            this.showLoader = false
            this.transfers = response.data.data
            response.data.data.forEach(transfer => {
              transfer.amount = transfer.amount ? this.$filters.formatCurrency(transfer.amount) : 'N/A'
              transfer.account_name = transfer.account_name ? this.transfer.account_name : 'N/A'
            })
            this.getNextSet()
            this.getPrevSet()
          })
    },
    getNextSet() {
      const data = {
        token: this.token,
        start: this.start + 20
      }
      this.$http.post(`transfers/list`, data)
          .then(response => {
            this.NextTransfers = response.data.data
          })
    },
    getPrevSet() {
      const data = {
        token: this.token,
        start: this.start - 20
      }
      this.$http.post(`transfers/list`, data)
          .then(response => {
            this.PrevTransfers = response.data.data
          })
    },
    getPayoutAccounts() {
      const data = {
        token: this.token
      }
      this.showLoader = true
      this.$http.post(`payout/accounts`, data)
          .then((res) => {
            this.showLoader = false
            this.payOutAccounts = res.data.banks
          })
    },
  }
}


</script>
