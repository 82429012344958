<template>
  <MasterLayout>
    <div class="container">
      <h3 class="page-title">Settings</h3>
      <ul class="nav nav-pills mb-4">
        <li class="nav-item">
          <a
            @click="changeTab('profile')"
            :class="{ active: tab === 'profile' }"
            class="nav-link"
            aria-current="page"
            href="#"
          >
            Profile
          </a>
        </li>
        <li class="nav-item">
          <a
            @click="changeTab('teams')"
            :class="{ active: tab === 'teams' }"
            class="nav-link"
            href="#"
          >
            Team
          </a>
        </li>
        <li class="nav-item">
          <a
            @click="changeTab('payOutAccount')"
            :class="{ active: tab === 'payOutAccount' }"
            class="nav-link"
            href="#"
          >
            Payout accounts
          </a>
        </li>
        <li class="nav-item">
          <a
            @click="changeTab('integrations')"
            :class="{ active: tab === 'integrations' }"
            class="nav-link"
            href="#"
            tabindex="-1"
          >
            Integrations
          </a>
        </li>
        <li class="nav-item">
          <a
            @click="changeTab('messages')"
            :class="{ active: tab === 'messages' }"
            class="nav-link"
            href="#"
            tabindex="-1"
          >
            Messages
          </a>
        </li>
      </ul>

      <!--        <div class="settings-menu mb-3">-->
      <!--          <div class="me-3 cursor-pointer" :class="[tab === 'profile' ? 'text-primary' : null]">Profile</div>-->
      <!--          <div class="cursor-pointer me-3" :class="[tab === 'teams' ? 'text-primary' : null]">Team</div>-->
      <!--          <div class="me-3 cursor-pointer" :class="[tab === 'payOutAccount' ? 'text-primary' : null]">Payout out accounts</div>-->
      <!--          <div class="cursor-pointer" :class="[tab === 'integrations' ? 'text-primary' : null]">Integrations</div>-->
      <!--        </div>-->

      <div class="row" v-if="tab === 'profile'">
        <div class="col-xxl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Personal Information</h4>
            </div>
            <div class="card-body">
              <form
                method="post"
                name="myform"
                class="personal_validate"
                novalidate="novalidate"
              >
                <div class="row g-4">
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Full Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="full_name"
                      placeholder=""
                      name="full_name"
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Business name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      name="businessname"
                      v-model="business_name"
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="email"
                      placeholder=""
                      name="email"
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      name="phone"
                      v-model="phone"
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      name="address"
                      v-model="address"
                    />
                  </div>
                  

                  <div class="col-12">
                    <button
                      class="btn btn-primary"
                      @click="saveProfile"
                      :disabled="isButtonDisabled"
                      :style="[
                        isButtonDisabled ? { cursor: 'not-allowed' } : null,
                      ]"
                    >
                      <span v-if="!isButtonDisabled">Save</span>
                      <pulse-loader
                        size="15px"
                        v-if="isButtonDisabled"
                      ></pulse-loader>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">User Profile</h4>
            </div>
            <div class="card-body">
              <form action="#">
                <div class="row g-3">
                  <div class="col-xxl-12 col-12 mb-3">
                    <div class="d-flex align-items-center">
                      <img
                        class="me-3 rounded-circle me-0 me-sm-3"
                        src="@/assets/img/profile/3.png"
                        width="55"
                        height="55"
                        alt=""
                      />
                      <div class="media-body">
                        <h4 class="mb-0">
                          {{ userProfile ? userProfile.legal_name : "" }}
                        </h4>
                        <p class="mb-0">Max file size is 20mb</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-12 col-12 mb-3">
                    <form class="passportForm">
                      <div class="form-file mt-3">
                        <input
                          type="file"
                          name="logo"
                          class="form-file-input"
                          id="customFile"
                        />
                        <label class="form-file-label" for="customFile">
                        </label>
                      </div>
                      <div class="mt-5">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          @click="uploadFile"
                          :disabled="isFileBtnDisabled"
                          :style="[
                            isFileBtnDisabled
                              ? { cursor: 'not-allowed' }
                              : null,
                          ]"
                        >
                          <span v-if="!isFileBtnDisabled">Save</span>
                          <pulse-loader
                            size="15px"
                            v-if="isFileBtnDisabled"
                          ></pulse-loader>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div class="col-xxl-12 col-12 mb-3"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Password Update</h4>
            </div>
            <div class="card-body">
              <form action="#">
                <div class="row g-3">
                  <div class="col-12 mb-3">
                    <label class="form-label">New password</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="**********"
                      v-model="oldPass"
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <label class="form-label">Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="**********"
                      v-model="newPass"
                    />
                  </div>
                  <div class="col-12 col-12 mb-3">
                    <button
                      class="btn btn-primary"
                      @click="updatePassword"
                      :disabled="isPassButtonDisabled"
                      :style="[
                        isPassButtonDisabled ? { cursor: 'not-allowed' } : null,
                      ]"
                    >
                      <span v-if="!isPassButtonDisabled">Save</span>
                      <pulse-loader
                        size="15px"
                        v-if="isPassButtonDisabled"
                      ></pulse-loader>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="tab === 'teams'">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end">
                <router-link
                  class="
                    btn btn-outline-primary btn-sm
                    d-inline-flex
                    align-items-center
                  "
                  :to="{ name: 'add-team-member' }"
                >
                  <i class="ri ri-add-line mr-2"></i>Add Team Member
                </router-link>
              </div>
              <pulse-loader size="15px" v-if="showTeamsLoader"></pulse-loader>
              <div class="table-responsive mt-3">
                <table
                  class="table table-striped py-5 mb-5"
                  v-if="!showTeamsLoader"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Team Id</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tm, i) in paginatedTeams" :key="i">
                      <td>{{ tm.LEGAL_NAME }}</td>
                      <td>{{ tm.PHONE ? tm.PHONE : "N/A" }}</td>
                      <td>{{ tm.EMAIL }}</td>
                      <td>{{ tm.TEAM_ID }}</td>
                      <td>
                        <button
                          class="btn btn-outline-secondary btn-sm me-2"
                          @click="suspendTeamMember(tm)"
                        >
                          Suspend
                        </button>
                        <button
                          class="btn btn-outline-danger btn-sm"
                          @click="deleteTeamMember(tm)"
                          href="create-invoice.html"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Paginator
          :total-pages="totalPages"
          :per-page="pageSize"
          :current-page="teamsCurrentPage"
          @pagechanged="onPageChange"
          @getPageSize="getPageSize"
          :pageSize="pageSize"
          :showPageSizeDropdown="true"
        />
      </div>
      <div v-if="tab === 'payOutAccount'">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">New Bank</h4>
          </div>
          <div class="card-body">
            <form action="#">
              <div class="row g-3">
                <div class="col-md-4 mb-3">
                  <label class="form-label">Bank</label>
                  <select class="form-control" v-model="bank_code">
                    <option value="select bank" disabled>select bank</option>
                    <option
                      v-for="bank in allBanks.data"
                      :key="bank.BANK_CODE"
                      :value="bank.BANK_CODE"
                    >
                      {{ bank.BANK_NAME }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="form-label">Account Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="0213452925"
                    v-model="account_number"
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label class="form-label">Account Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="account_name"
                  />
                </div>

                <div class="col-12 col-12">
                  <button
                    class="btn btn-outline-primary btn-sm me-3"
                    @click="validateBankAccount"
                    :disabled="isValidateButtonDisabled"
                    :style="[
                      isValidateButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : null,
                    ]"
                  >
                    <span v-if="!isValidateButtonDisabled">Validate</span>
                    <pulse-loader
                      size="15px"
                      v-if="isValidateButtonDisabled"
                    ></pulse-loader>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="saveBankAccount"
                    :disabled="isSavedButtonDisabled || accountNotValidated"
                    :style="[
                      isSavedButtonDisabled ? { cursor: 'not-allowed' } : null,
                    ]"
                  >
                    <span v-if="!isSavedButtonDisabled">Save</span>
                    <pulse-loader
                      size="15px"
                      v-if="isSavedButtonDisabled"
                    ></pulse-loader>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-header flex-row">
            <h4 class="card-title">Payout Accounts</h4>
            <!-- <a class="btn btn-primary" href="create-invoice.html"><span><i class="bi bi-plus"></i></span>Create Account</a> -->
          </div>
          <div class="card-body">
            <div class="payments-content">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Account Number</th>
                      <th>Account Name</th>
                      <th>Account Bank</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(poAccount, i) in paginatedPayouts" :key="i">
                      <td>
                        {{ poAccount.ACCOUNT_NUMBER }}
                      </td>
                      <td>{{ poAccount.ACCOUNT_NAME }}</td>
                      <td>{{ poAccount.BANK_NAME }}</td>
                      <td>
                        <span class="badge px-3 py-2 bg-success">Enabled</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- <Paginator
          :total-pages="totalPages"
          :per-page="pageSize"
          :current-page="payOutAccountsCurrentPage"
          @pagechanged="onPageChange"
          @getPageSize="getPageSize"
          :pageSize="pageSize"
          :showPageSizeDropdown="true"
        /> -->
      </div>
      <div class="row" v-if="tab === 'integrations'">
        <div class="col-xxl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Paystack</h4>
            </div>
            <div class="card-body">
              <form action="#">
                <div class="row g-3">
                  <div class="col-xl-6 col-md-6">
                    <label class="form-label">Secret Key</label>
                    <input
                      type="text"
                      name="usd_amount"
                      class="form-control"
                      placeholder="Enter Passphrase"
                      v-model="paystackSecretKy"
                    />
                  </div>
                  <div class="col-xl-6 col-md-6">
                    <label class="form-label">Public Key</label>
                    <input
                      type="text"
                      name="usd_amount"
                      class="form-control"
                      placeholder="Re-enter passphrase"
                      v-model="paystackPublicKy"
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn btn-primary"
                      @click="savePaystackKeys"
                      :disabled="isPaystackButtonDisabled"
                      :style="[
                        isPaystackButtonDisabled
                          ? { cursor: 'not-allowed' }
                          : null,
                      ]"
                    >
                      <span v-if="!isPaystackButtonDisabled"
                        >Save API Keys</span
                      >
                      <pulse-loader
                        size="15px"
                        v-if="isPaystackButtonDisabled"
                      ></pulse-loader>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xxl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Flutterwave</h4>
            </div>
            <div class="card-body">
              <form action="#">
                <div class="row g-3">
                  <div class="col-xl-6 col-md-6">
                    <label class="form-label">Secret Key</label>
                    <input
                      type="text"
                      name="usd_amount"
                      class="form-control"
                      v-model="flutterwaveSecretKy"
                      placeholder="Enter Passphrase"
                    />
                  </div>
                  <div class="col-xl-6 col-md-6">
                    <label class="form-label">Public Key</label>
                    <input
                      type="text"
                      name="usd_amount"
                      class="form-control"
                      v-model="flutterwavePublicKy"
                      placeholder="Re-enter passphrase"
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn btn-primary"
                      @click="saveFlutterwaveKeys"
                      :disabled="isFlutterwaveButtonDisabled"
                      :style="[
                        isFlutterwaveButtonDisabled
                          ? { cursor: 'not-allowed' }
                          : null,
                      ]"
                    >
                      <span v-if="!isFlutterwaveButtonDisabled"
                        >Save API Keys</span
                      >
                      <pulse-loader
                        size="15px"
                        v-if="isFlutterwaveButtonDisabled"
                      ></pulse-loader>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xxl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">NIBBS Mandate</h4>
            </div>
            <div class="card-body">
              <form action="#">
                <div class="row g-3">
                  <div class="col-xl-6 col-md-6">
                    <label class="form-label">Integration Key</label>
                    <input
                      type="text"
                      name="usd_amount"
                      class="form-control"
                      placeholder="Enter Passphrase"
                      v-model="nibbsSecretKy"
                    />
                  </div>

                  <div class="col-xl-6 col-md-6">
                    <label class="form-label">Public Key</label>
                    <input
                      type="text"
                      name="usd_amount"
                      class="form-control"
                      placeholder="Enter Passphrase"
                      v-model="nibbsPublicKy"
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn btn-primary"
                      @click="saveNibbsKeys"
                      :disabled="isNibbsButtonDisabled"
                      :style="[
                        isNibbsButtonDisabled
                          ? { cursor: 'not-allowed' }
                          : null,
                      ]"
                    >
                      <span v-if="!isNibbsButtonDisabled">Save API Keys</span>
                      <pulse-loader
                        size="15px"
                        v-if="isNibbsButtonDisabled"
                      ></pulse-loader>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="tab === 'messages'">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <pulse-loader
                size="15px"
                v-if="showMessagesLoader"
              ></pulse-loader>
              <div class="table-responsive mt-3">
                <table
                  class="table table-striped py-5 mb-5"
                  v-if="!showMessagesLoader"
                >
                  <thead>
                    <tr>
                      <th>Message Type</th>
                      <th>Recipients</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Pre-due date reminder</td>
                      <td></td>
                      <td>
                        <button
                          class="btn btn-outline-secondary btn-sm me-2"
                          @click="toggleSlideOut('8')"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Due date reminder</td>
                      <td></td>
                      <td>
                        <button
                          class="btn btn-outline-secondary btn-sm me-2"
                          @click="toggleSlideOut('9')"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Late Payment</td>
                      <td></td>
                      <td>
                        <button
                          class="btn btn-outline-secondary btn-sm me-2"
                          @click="toggleSlideOut('10')"
                        >
                          View
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>Default</td>
                      <td></td>
                      <td>
                        <button
                          class="btn btn-outline-secondary btn-sm me-2"
                          @click="toggleSlideOut('11')"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MasterLayout>
  <Modal :visible="newCollectionVisible" @close="newCollectionVisible = false">
    <UpdateMessage :messageopen="messageOpen" v-if="newCollectionVisible"/>
  </Modal>
</template>
<script>
import MasterLayout from "@/views/Auth/MasterLayout";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import Paginator from "@/components/Paginator";
import Modal from "@/components/Modal";
import UpdateMessage from "@/components/UpdateMessage";

export default {
  components: {
    MasterLayout,
    PulseLoader,
    Paginator,
    UpdateMessage,
    Modal
  },
  data() {
    return {
      messageOpen:"",
      newCollectionVisible: false,
      isButtonDisabled: false,
      tab: "profile",
      userProfile: {},
      full_name: "",
      email: "",
      address: "",
      business_name: "",
      phone:"",
      newPass: "",
      oldPass: "",
      token: localStorage.getItem("token"),
      isPassButtonDisabled: false,
      showTeamsLoader: false,
      showMessagesLoader: false,
      isFileBtnDisabled: false,
      paginatedTeams: [],
      pages: [],
      pageSize: 10,
      totalPages: 1,
      currentPage: 1,
      tableCount: 0,
      teams: [],
      payOutAccounts: [],
      paginatedPayouts: [],
      teamsCurrentPage: 1,
      payOutAccountsCurrentPage: 1,
      allBanks: [],
      bank_code: "select bank",
      account_number: "",
      account_name: "",
      isValidateButtonDisabled: false,
      isSavedButtonDisabled: false,
      accountNotValidated: true,
      paystackSecretKy: null,
      paystackPublicKy: null,
      isPaystackButtonDisabled: false,

      flutterwaveSecretKy: null,
      flutterwavePublicKy: null,
      isFlutterwaveButtonDisabled: false,

      nibbsSecretKy: null,
      nibbsPublicKy: null,
      isNibbsButtonDisabled: false,
    };
  },
  mounted() {
    this.getUserProfile();
    this.getTeams();
    this.getPayoutAccounts();
    this.getBanks();
    this.getReminderMessages();
  },
  watch: {},
  methods: {
    saveNibbsKeys(e) {
      e.preventDefault();
      if (!this.nibbsSecretKy) {
        createToast("Enter  Nibbs secret key", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      if (!this.nibbsPublicKy) {
        createToast("Enter  Nibbs public key", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }

      const payload = {
        live_secret_key: this.nibbsSecretKy,
        live_public_key: this.nibbsPublicKy,
      };
      const data = {
        token: localStorage.getItem("token"),
        data: payload,
      };
      this.isNibbsButtonDisabled = true;
      this.$http.post(`integrations/nibbs_mandate`, data).then((res) => {
        this.isNibbsButtonDisabled = false;
        createToast("Nibbs Mandate keys created successfully!", {
          type: "success",
        });
      });
    },
    saveFlutterwaveKeys(e) {
      e.preventDefault();
      if (!this.flutterwaveSecretKy) {
        createToast("Enter  flutterwave secret key", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      if (!this.flutterwavePublicKy) {
        createToast("Enter  flutterwave public key", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }

      const payload = {
        live_secret_key: this.flutterwaveSecretKy,
        live_public_key: this.flutterwavePublicKy,
      };
      const data = {
        token: localStorage.getItem("token"),
        data: payload,
      };
      this.isFlutterwaveButtonDisabled = true;
      this.$http.post(`integrations/flutterwave`, data).then((res) => {
        this.isFlutterwaveButtonDisabled = false;
        createToast("Flutterwave keys created successfully!", {
          type: "success",
        });
      });
    },
    savePaystackKeys(e) {
      e.preventDefault();
      if (!this.paystackSecretKy) {
        createToast("Enter  paystack secret key", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      if (!this.paystackPublicKy) {
        createToast("Enter  paystack public key", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }

      const payload = {
        live_secret_key: this.paystackSecretKy,
        live_public_key: this.paystackPublicKy,
      };
      const data = {
        token: localStorage.getItem("token"),
        data: payload,
      };
      this.isPaystackButtonDisabled = true;
      this.$http.post(`integrations/paystack`, data).then((res) => {
        this.isPaystackButtonDisabled = false;
        createToast("Paystack keys created successfully!", {
          type: "success",
        });
      });
    },
    validateBankAccount(e) {
      e.preventDefault();
      if (this.bank_code == "select bank") {
        createToast("select a bank account ", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      if (!this.account_number) {
        createToast("Account number is required", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      const payload = {
        bank_code: this.bank_code,
        account_number: this.account_number,
      };
      const data = {
        token: localStorage.getItem("token"),
        data: payload,
      };
      this.isValidateButtonDisabled = true;
      this.accountNotValidated = true;
      this.$http.post(`validate/account`, data).then((res) => { 
        this.isValidateButtonDisabled = false;
        if(res.data.status === 'success'){

          
          createToast(res.data.message, {
            type: "success",
          });
          this.account_name = res.data.data.account_name;
          this.accountNotValidated = false;
        

        }
        
        
      });
    },
    saveBankAccount(e) {
      e.preventDefault();
      if (this.bank_code == "select bank") {
        createToast("select a bank account ", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      if (!this.account_number) {
        createToast("Account number is required", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      if (!this.account_name) {
        createToast("Account name is required", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }
      const bankId = this.allBanks.data.filter(
        (bank) => bank.BANK_CODE === this.bank_code
      )[0].BANK_ID;

      const payload = {
        bank_code: this.bank_code,
        account_number: this.account_number,
        bank_id: bankId,
        account_name: this.account_name,
      };
      const data = {
        token: localStorage.getItem("token"),
        data: payload,
      };
      this.isSavedButtonDisabled = true;
      this.$http.post(`save/account`, data).then((res) => {
        this.isSavedButtonDisabled = false;
        createToast(res.data.message, {
          type: "success",
        });
      });
    },
    getPageSize(pageSize) {
      this.pageSize = pageSize;
      if (this.tab === "teams") {
        this.totalPages = Math.ceil(this.teams.length / this.pageSize);
        this.tableCount = this.teams.length;
        this.paginatedTeams = this.paginate(this.teams);
      } else {
        this.totalPages = Math.ceil(this.payOutAccounts.length / this.pageSize);
        this.tableCount = this.payOutAccounts.length;
        this.paginatedPayouts = this.paginate(this.payOutAccounts);
      }
    },
    onPageChange(page) {
      if (this.tab === "teams") {
        this.teamsCurrentPage = page;
        this.paginatedTeams = this.paginate(this.teams);
      } else {
        this.payOutAccountsCurrentPage = page;
        this.paginatedPayouts = this.paginate(this.payOutAccounts);
      }
    },
    paginate(data) {
      let numberOfPages = Math.ceil(data.length / this.pageSize);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
      let pageSize = this.pageSize;
      let from;
      let to;
      if (this.tab === "teams") {
        from = this.teamsCurrentPage * pageSize - pageSize;
      } else {
        to = this.payOutAccountsCurrentPage * pageSize;
      }
      return data.slice(from, to);
    },
    getTeams() {
      const data = {
        token: this.token,
      };
      this.showTeamsLoader = true;
      this.$http.post(`team`, data).then((res) => {
        this.showTeamsLoader = false;
        this.totalPages = Math.ceil(res.data.employees.count / this.pageSize);
        this.tableCount = res.data.employees.count;
        this.teams = res.data.employees.all_emp;
      });
    },
    changeTab(tab) {
      this.tab = tab;
      if (this.tab === "teams") {
        this.totalPages = Math.ceil(this.teams.length / this.pageSize);
        this.tableCount = this.teams.length;
        this.paginatedTeams = this.paginate(this.teams);
      } else {
        this.totalPages = Math.ceil(this.payOutAccounts.length / this.pageSize);
        this.tableCount = this.payOutAccounts.length;
        this.paginatedPayouts = this.paginate(this.payOutAccounts);
      }
    },
    uploadFile(e) {
      e.preventDefault();
      const uploadFormData = new FormData(
        document.querySelector(".passportForm")
      );
      uploadFormData.append("token", this.token);
      this.isFileBtnDisabled = true;
      this.$http.post(`profile/update/logo`, uploadFormData).then((res) => {
        this.isFileBtnDisabled = false;
        createToast("Profile picture saved successfully", {
          type: "success",
        });
      });
    },
    suspendTeamMember(teamMember) {
      const confirm = window.confirm(
        "Are you sure you want to suspend this team member?"
      );
      if (confirm) {
        const data = {
          token: this.token,
          team_member_id: teamMember.TEAM_ID,
        };
        this.showTeamsLoader = true;
        this.$http.post(`team/suspend`, data).then((res) => {
          this.showTeamsLoader = false;
          createToast("Team memebr suspended successfully", {
            type: "success",
          });
        });
      }
    },
    deleteTeamMember(teamMember) {
      const confirm = window.confirm(
        "Are you sure you want to delete this team member?"
      );
      if (confirm) {
        const data = {
          token: this.token,
          team_member_id: teamMember.TEAM_ID,
        };
        this.showTeamsLoader = true;
        this.$http.post(`team/remove`, data).then((res) => {
          this.showTeamsLoader = false;
          createToast("Team memeber deleted successfully", {
            type: "success",
          });
          this.getTeams();
        });
      }
    },
    updatePassword() {
      if (!this.oldPass) {
        createToast("Enter new password", {
          type: "danger",
        });
        return;
      }
      if (!this.newPass) {
        createToast("Confirm new password", {
          type: "danger",
        });
        return;
      }
      if (this.newPass != this.oldPass) {
        createToast("New password is not same as confirm password", {
          type: "danger",
        });
        return;
      }
      const data = {
        token: localStorage.getItem("token"),
        confirmpassword: this.oldPass,
        newpassword: this.newPass,
      };
      this.isPassButtonDisabled = true;
      this.$http.post(`api/v2/loan/changePassword`, data).then(() => {
        this.isPassButtonDisabled = false;
        createToast("Profile successfully saved", {
          type: "success",
        });

      });
    },
    getUserProfile() {
      const data = {
        token: this.token,
      };
      this.showLoader = true;
      this.$http.post(`profile`, data).then((res) => {
        this.showLoader = false;
        this.userProfile = res.data.profile;
        this.full_name = res.data.profile.legal_name;
        this.email = res.data.profile.email;
        this.address = res.data.profile.address;
        this.phone = res.data.profile.phone;
        this.business_name = res.data.profile.business_name;
        this.paystackSecretKy = res.data.app_keys.paystack_key;
        this.paystackPublicKy = res.data.app_keys.paystack_public_key;
        this.flutterwaveSecretKy = res.data.app_keys.flutterwave_key;
        this.flutterwavePublicKy = res.data.app_keys.flutterwave_public_key;
        this.nibbsSecretKy = res.data.app_keys.cyberpay_integration_key;
        this.nibbsPublicKy = res.data.app_keys.cyberpay_public_key;
      });
    },
    getBanks() {
      const data = {
        token: this.token,
      };
      this.showLoader = true;
      this.$http.post(`banks`, data).then((res) => {
        this.showLoader = false;
        this.allBanks = res.data;
      });
    },
    getPayoutAccounts() {
      const data = {
        token: this.token,
      };
      this.showLoader = true;
      this.$http.post(`payout/accounts`, data).then((res) => {
        this.showLoader = false;
        this.payOutAccounts = res.data.banks;
      });
    },

    saveProfile(e) {
      e.preventDefault();
      const payload = {
        legal_name: this.full_name,
        email: this.email,
        address: this.address,
        business_name: this.business_name,
        phone: this.phone
      };
      const data = {
        token: localStorage.getItem("token"),
        data: payload,
      };
      this.isButtonDisabled = true;
      this.$http.post(`profile/update`, data).then(() => {
        this.getUserProfile();
        this.isButtonDisabled = false;
        createToast("Profile successfully saved", {
          type: "success",
        });
      });
    },
    toggleSlideOut(e) {
      console.log(e)
      this.messageOpen = e;
      this.newCollectionVisible = !this.newCollectionVisible;
      // const slideOut = document.querySelector(".messages-slideout");
      // const closeBtn = document.querySelector(".m-close-btn");
      // const slideOutContent = document.querySelector(".m-slide-out-content");
      // if (slideOut.classList.contains("messages-slide-out-width")) {
      //   slideOut.classList.add("messages-slide-in-width");
      //   slideOut.classList.remove("messages-slide-out-width");
      //   closeBtn.classList.remove("hide");
      //   slideOutContent.classList.remove("hide");
      // } else {
      //   slideOut.classList.remove("messages-slide-in-width");
      //   slideOut.classList.add("messages-slide-out-width");
      //   closeBtn.classList.add("hide");
      //   slideOutContent.classList.add("hide");
      // }
    },
    getReminderMessages() {
        const data = {
            token: this.token,
        }
        this.$http.post(`collection/message_settings`, data)
        .then(response => {
            // this.messages = response.data.data
            // console.log(response.data)
            // let destArr = []
            // response.data.data.forEach(el => {
            //     for(let dest in JSON.parse(el.destination)) {
            //         console.log(JSON.parse(el.destination)[dest])
            //         if(JSON.parse(el.destination)[dest]){
            //             destArr.push(dest)
            //         }
            //     }
            //     el.destinations = destArr.join(", ")
            // })                
        }).catch(error => {
            console.log(error)
        })
    }
  },
};
</script>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
