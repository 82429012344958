<template>
  <MasterLayout>
    <div class="container">
      <h3 class="page-title">Recovery</h3>
      <div class="row mt-4">
        <div class="col-xl-6 col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <div class="stat-widget d-flex align-items-center bg-white">
                <div class="widget-icon me-3 bg-primary"><i class="ri-file-copy-2-line"></i></div>
                <div class="widget-content">
                  <h3>{{ connections.length }}</h3>
                  <p>Total Connections</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="stat-widget d-flex align-items-center bg-white">
                <div class="widget-icon me-3 bg-success"><i class="ri-file-list-3-line"></i></div>
                <div class="widget-content">
                  <h3>{{ messages.length }}</h3>
                  <p>Total messages sent</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-4">
            <div class="card-header d-flex align-items-center">
              <h4 class="card-title d-flex align-items-center">
                Recent connections
                <button
                    @click="getTransactions()"
                    class="btn btn-light btn-sm d-flex align-items-center justify-content-center rounded-circle ms-2"
                    style="width: 35px; height: 35px"
                >
                  <i class="ri-refresh-line"></i>
                </button>
              </h4>
            </div>
            <div class="card-body">
              <template v-if="loading.connections">
                <div class="position-relative">
                  <div class="card-loader" style="height: 40px"></div>
                </div>
                <div class="position-relative mt-2">
                  <div class="card-loader" style="height: 40px"></div>
                </div>
              </template>
              <template v-else>
                <ul class="list-group">
                  <li
                      v-for="t in connections.slice(0, 4)" :key="t.transactionId" @click="t.qrStatus === 1 && showCollectionDetails(t.transactionId)"
                      class="list-group-item d-flex justify-content-between align-items-center py-2" :class="{ 'cursor-pointer': t.qrStatus === 1 }"
                  >
                    <div>
                      <div>{{ t.name }} - {{ t.phone }}</div>
                      <div class="text-muted">{{ t.company }}</div>
                    </div>
                    <div v-if="t.qrStatus === 0" class="d-flex align-items-center">
                      <div style="width: 10px; height: 10px;" class="rounded-circle bg-warning me-2"></div>
                      <span class="text-warning">Not connected</span>
                    </div>
                    <div v-if="t.qrStatus === 1" class="d-flex align-items-center">
                      <div style="width: 10px; height: 10px;" class="rounded-circle bg-success me-2"></div>
                      <span class="text-success">Connected</span>
                    </div>
                  </li>
                </ul>
                <div class="mt-4">
                  <button @click="modals.connections = true" class="btn btn-light btn-xs px-3 rounded-pill">See all</button>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="card-title">Add a new whatsapp connection</h4>
                <button @click="modals.new = true" class="btn btn-primary btn-sm px-3 rounded-pill d-flex align-items-center ms-2">
                  <i class="ri-add-line me-2"></i>Add
                </button>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <h4 class="card-title">All recovery messages sent</h4>
            </div>
            <div class="card-body">
              <template v-if="loading.messages">
                <div class="position-relative">
                  <div class="card-loader" style="height: 40px"></div>
                </div>
                <div class="position-relative mt-2">
                  <div class="card-loader" style="height: 40px"></div>
                </div>
              </template>
              <template v-else>
                <ul class="list-group">
                  <li v-for="message in messages.slice(0, 4)" class="list-group-item">
                    {{ message.body }}
                    <div class="d-flex justify-content-between text-muted">
                      <small>{{ message.subject }}</small>
                      <small>{{ $filters.formatDistance(message.created_date) }}</small>
                    </div>
                  </li>
                </ul>
                <div class="mt-4">
                  <button @click="modals.messages = true" class="btn btn-light btn-xs px-3 rounded-pill">See all</button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MasterLayout>

  <Modal :visible="modals.new" @close="modals.new = false">
    <NewRecovery @done="getTransactions()"/>
  </Modal>

  <Modal :visible="modals.connections" @close="modals.connections = false">
    <RecoveryConnections
        :connections="connections"
        :loading="loading.connections"
        @details="showCollectionDetails($event)"
        @refresh="getTransactions()"
    />
  </Modal>

  <Modal :visible="modals.messages" @close="modals.messages = false">
    <Messages :messages="messages" :loading="loading.messages"/>
  </Modal>

  <Modal :visible="modals.details && selected" @close="modals.details = false">
    <ConnectionDetails :id="selected"/>
  </Modal>
</template>

<script>
import MasterLayout from "@/views/Auth/MasterLayout";
import Modal from "@/components/Modal";
import NewRecovery from "@/components/NewRecovery";
import ConnectionDetails from "@/components/recovery/ConnectionDetails";
import RecoveryConnections from "@/components/recovery/RecoveryConnections";
import Messages from "@/components/recovery/Messages";

export default {
  name: "Recovery",
  components: { Messages, RecoveryConnections, ConnectionDetails, NewRecovery, Modal, MasterLayout },
  data() {
    return {
      loading: {
        connections: false,
        messages: false,
      },
      modals: {
        new: false,
        details: false,
        connections: false,
        messages: false,
      },
      messages: [],
      connections: [],
      selected: null,
    }
  },
  created() {
    this.getTransactions();
    this.getMessagesLog();
  },
  methods: {
    async getTransactions() {
      this.loading.connections = true;
      const { api_key } = this.$store.state.user;
      const res = await this.$http.get('https://whatsapp.creditclan.com/customer/transactions', { headers: { 'x-api-key': api_key } });
      this.connections = res.data.data;
      this.loading.connections = false;
    },
    async getMessagesLog() {
      this.loading.messages = true;
      const res = await this.$http.post('/collection/messages', {});
      this.messages = res?.data?.data ?? [];
      this.loading.messages = false;
    },
    showCollectionDetails(transaction_id) {
      this.modals.connections = false;
      this.selected = transaction_id;
      this.modals.details = true;
    }
  },
}
</script>
