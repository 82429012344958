<template>
  <MasterLayout>
    <div v-if="showLoader" class="container">
      <div class="position-relative" style="width: 150px; height: 40px">
        <div class="card-loader h-100"></div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="position-relative">
            <div class="card-loader" style="height: 230px"></div>
          </div>
          <div class="position-relative mt-4">
            <div class="card-loader" style="height: 200px"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative">
            <div class="card-loader" style="height: 200px"></div>
          </div>
          <div class="position-relative mt-4">
            <div class="card-loader" style="height: 200px"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <h3 class="page-title">Overview</h3>
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <!-- 1 -->
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Statistics</h4>
            </div>
            <div class="card-body">
              <div class="chartjs-size-monitor">
                <div class="chartjs-size-monitor-expand">
                  <div class=""></div>
                </div>
                <div class="chartjs-size-monitor-shrink">
                  <div class=""></div>
                </div>
              </div>
              <div class="donut-chart">
                <apexchart type="donut" :options="statisticsChart" :series="donutSeries"></apexchart>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Recent collections</h4>
              <router-link to="requests">See more</router-link>
            </div>
            <div class="card-body">
              <div class="invoice-content">
                <ul>
                  <li class="d-flex justify-content-between active" v-for="(transHistory, i) in transactionHistory" :key="i">
                    <div class="d-flex align-items-center">
                      <div class="invoice-info">
                        <h5 class="mb-0">{{ transHistory.legal_name }}</h5>
                        <p> {{ transHistory.date_paid.split(' ')[0] }} at {{ transHistory.date_paid.split(' ')[1] }}</p>
                      </div>
                    </div>
                    <div class="text-end">
                      <h5 class="mb-2">+{{ $filters.formatCurrency(transHistory.amount_paid) }}</h5>
                      <span class=" text-white bg-success">Paid</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
          <!-- 1 -->
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Collections Stats</h4>
            </div>
            <div class="card-body">
              <swiper :slides-per-view="1.5" :space-between="20">
                <swiper-slide v-for="slide in collectionSliders" :key="slide">
                  <div class="stat-widget d-flex align-items-center rounded-lg py-4 px-4">
                    <div class="widget-icon me-3 d-flex justify-content-center align-items-center bg-primary">
                      <i class="ri-wallet-line"></i>
                    </div>
                    <div class="widget-content text-start">
                      <h3>{{ slide.amount }}</h3>
                      <p>{{ slide.title }}</p>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="row" v-if="showLoader">
                <div class="col-xl-6 mt-3 position-relative" v-for="(c,i) in 4" :key="i">
                  <div class="card-loader  small"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div id="user-activity" class="card" data-aos="fade-up">
            <div class="card-header">
              <h4 class="card-title">{{ showLoader ? '' : 'Revenue' }}</h4>
            </div>
            <div class="card-body">
              <div class="chartjs-size-monitor">
                <div class="chartjs-size-monitor-expand">
                  <div class=""></div>
                </div>
                <div class="chartjs-size-monitor-shrink">
                  <div class=""></div>
                </div>
              </div>
              <div class="card-loader large" v-if="showLoader"></div>
              <highcharts :options="revenueChart" v-if="!showLoader" class="" style="height: 247px"></highcharts>
            </div>
          </div>
          <!-- 3 -->
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Goals Targets</h4>
            </div>
            <div class="card-body">
              <div class="budget-content">
                <FullCalendar refs="fullCalendar" :plugins="calendarPlugins" :options="calendarOptions" ref="fullCalendar"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MasterLayout>
</template>
<script>

import MasterLayout from '@/views/Auth/MasterLayout'
import { Chart } from "highcharts-vue";
import TableLoader from "@/components/TableLoader";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  components: {
    FullCalendar,
    MasterLayout,
    Highcharts: Chart,
    TableLoader,
    PulseLoader,
    VueCal,
    Swiper, SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      calendarApi: null,
      transactionHistory: [],
      goals: [],
      dashboardStats: {},
      showGoalsLoader: true,
      showLoader: true,
      start_date: new Date(),
      formattedStartDate: null,
      events: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        events: []
      },
      revenueChart: {
        title: false,
        chart: {
          type: 'column'
        },
        xAxis: {
          categories: [],
          crosshair: false,
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [
          {
            name: "Revenue",
            data: [],
            color: "#0d40c6"
          }
        ]
      },
      statisticsChart: {
        chart: {
          type: 'donut',
        },
        labels: [],
        legend: {
          position: 'bottom',
          onItemClick: {
            toggleDataSeries: true
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
      },
      donutSeries: [],
      collectionSliders: []
    }
  },
  watch: {
    start_date(date) {
      this.formattedStartDate = this.formatDate(date)
    },
    calendarApi() {
      this.triggerVCalendarPaginationEvents()
    }
  },
  mounted() {
    this.getDashboardData()
  },
  created() {
    this.formattedStartDate = this.formatDate(new Date())
  },
  methods: {
    triggerVCalendarPaginationEvents() {
      document.querySelector('.fc-prev-button').addEventListener('click', () => {
        this.calendarApi.prev()
        this.formattedStartDate = this.formatDate(this.calendarApi.currentData.dateProfile.activeRange.start)
        this.formattedEndDate = this.formatDate(this.calendarApi.currentData.dateProfile.activeRange.end)
        this.getCalendar()
      })
      document.querySelector('.fc-next-button').addEventListener('click', () => {
        this.calendarApi.next()
        this.formattedStartDate = this.formatDate(this.calendarApi.currentData.dateProfile.activeRange.start)
        this.formattedEndDate = this.formatDate(this.calendarApi.currentData.dateProfile.activeRange.end)
        this.getCalendar()
      })
    },
    formatDate(dateStr) {
      let d = dateStr, month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    handleDateClick: function (arg) {
      alert('date click! ' + arg.dateStr)
    },
    getDashboardData() {
      this.getGoals()
      this.getDashboardStatistics()
      this.getTransactionHistory()
      this.getRevenueChartData()
      this.getDistributionChartData()
      this.getCalendar()
    },
    getDashboardStatistics() {
      this.$http.post(`collections/stats`, {})
          .then(response => {
            this.showLoader = false
            this.dashboardStats = response.data.data
            this.collectionSliders = [
              {
                title: 'Available balance',
                amount: this.dashboardStats.available_balance,
              },
              {
                title: 'Today',
                amount: this.dashboardStats.today,
              },
              {
                title: 'This month',
                amount: this.dashboardStats.this_month,
              },
              {
                title: 'Last month',
                amount: this.dashboardStats.last_month,
              },
              {
                title: 'Last week',
                amount: this.dashboardStats.last_week,
              },
              {
                title: 'Book balance',
                amount: this.dashboardStats.book_balance,
              },

            ]

          })
    },
    getCalendar() {
      const data = {
        "filterType": "1",
        "start": this.formattedStartDate,
        "end": this.formattedStartDate,
        "show_for_account_officer": false,
        "repay_type": "1",
        "schedule_type": false
      }
      this.$http.post(`calendar`, data)
          .then(response => {
            this.showLoader = false
            let events = [];
            response.data.events.forEach(event => {
              events.push({
                date: event.start,
                title: event.title,
                color: event.color
              })
            })
            this.calendarOptions.events = events
            this.calendarApi = this.$refs.fullCalendar.getApi()
          })
    },
    getDistributionChartData() {
      this.$http.post(`collections/distribution`, {})
          .then(response => {
            this.showLoader = false
            response.data.data.forEach(el => this.donutSeries.push(parseFloat(el)))
            response.data.labels.forEach(el => this.statisticsChart.labels.push(el))
          })
    },
    getTransactionHistory() {
      this.$http.post(`collections/transactions/history`, {})
          .then(response => {
            this.showLoader = false
            this.transactionHistory = response.data.data
          })
    },
    getRevenueChartData() {
      this.$http.post(`collections/revenue`, {})
          .then(response => {
            this.showLoader = false
            this.revenueChart.series[0].data = response.data.data.map(el => {
              return parseFloat(el)
            })
            this.revenueChart.xAxis.categories = response.data.dates
          })
    },
    getGoals() {
      this.$http.post(`collections/goals`, {})
          .then(response => {
            this.showGoalsLoader = false
            this.goals = response.data.message
          })
    },
  }
}
</script>
