<template>
  <div class="p-5">
    <h4 class="card-title">All recovery messages sent</h4>
    <div class="mt-4 mb-4 d-flex align-items-center">
      <input v-model="query" type="search" class="form-control rounded-pill" placeholder="Search by phone or name.."/>
    </div>
    <ul class="list-group mt-4">
      <li v-for="message in items" class="list-group-item">
        {{ message.body }}
        <div class="d-flex justify-content-between text-muted">
          <small>{{ message.subject }}</small>
          <small>{{ $filters.formatDistance(message.created_date) }}</small>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Messages",
  props: {
    messages: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      query: '',
    }
  },
  computed: {
    items() {
      if (this.query) return this.messages.filter(t => t.subject.includes(this.query));
      else return this.messages;
    }
  }
}
</script>
