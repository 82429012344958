<template>
  <div class="my-auto p-5">
    <h4 class="text-primary-dark">Register payment</h4>
    <form @submit.prevent="register">
      <div class="mb-3 mt-5">
        <label for="amount-paid" class="form-label">Amount paid</label>
        <input v-model="values.amount_paid" type="number" class="form-control" id="amount-paid" required/>
      </div>
      <div class="mb-2">
        <label for="date-paid" class="form-label">Date paid</label>
        <input v-model="values.date_paid" type="date" class="form-control" id="date-paid" required/>
      </div>
      <button type="submit" class="btn btn-primary btn-sm mt-5" :disabled="loading">
        <span v-if="loading" class="spinner-grow spinner-grow-sm me-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
        Register
      </button>
    </form>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";

export default {
  name: "RegisterPayment",
  props: {
    account: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    values: {
      date_paid: '',
      amount_paid: ''
    },
    loading: false
  }),
  methods: {
    async register() {
      if (!Object.keys(this.values).find((acc, key) => !this.values[key])) return;
      const payload = {
        token: localStorage.getItem('token'),
        collection_id: this.$route.params.id,
        ...this.values
      };
      this.loading = true;
      try {
        const res = await this.$http.post(`collection/register/payment`, payload);
        if (!res.data.status) createToast(res.data?.message || 'An error occurred, please try again', {
          type: 'danger', timeout: 5000
        }); else {
          this.$emit('done');
        }
      } catch (e) {
        createToast(e?.response.data?.message || 'An error occurred, please try again', { type: 'danger', timeout: 5000 });
      }
      this.loading = false;
    }
  }
}
</script>
