<template>
  <div v-if="completed" class="my-auto p-5">
    <h4 class="text-primary-dark m-0 fs-5">Confirm activation</h4>
    <p class="m-0 fs-6">Please note that this action cannot be reversed</p>

    <div class="d-flex align-items-center mt-5">
      <button @click="activate()" class="btn btn-primary btn-sm" :disabled="activating">
        <span v-if="activating" class="spinner-grow spinner-grow-sm me-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
        Yes, activate
      </button>
      <button @click="$emit('close')" class="btn btn-outline-danger btn-sm ms-2">No, cancel</button>
    </div>
  </div>
  <div v-else class="my-auto p-5">
    <i class="ri-error-warning-fill text-danger fs-1"></i>
    <h4 class="text-primary-dark m-0 fs-5 mt-4">Unable to activate collection</h4>
    <p class="m-0 fs-6">Customer is yet to provide requested data for this collection</p>
  </div>
</template>

<script>
export default {
  name: "ActivateRequest",
  props: {
    request: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      activating: false,
    }
  },
  methods: {
    async activate() {
      const payload = {
        token: localStorage.getItem('token'),
        collection_id: this.$route.params.id,
      };
      this.activating = true;
      await this.$http.post(`collection/activate`, payload);
      this.activating = false;
      await this.$router.replace('/requests');
    }
  },
  computed: {
    completed() {
      return +this.request.channels_activated === +this.request.total_channels;
    }
  }
}
</script>
