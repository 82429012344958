<template>
  <MasterLayout>
    <div class="container">
      <template v-if="loading">
        <div class="position-relative" style="width: 200px; height: 30px">
          <div class="card-loader h-100"></div>
        </div>
        <div class="position-relative mt-4">
          <div class="card-loader"></div>
        </div>
      </template>
      <template v-if="customers.length">
        <h3 class="page-title">Customers</h3>
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Date Added</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(customer, i) in customers" :key="i">
                  <td>
                    <img
                        :src="customer.FILE_NAME ? customer.FILE_NAME : require('@/assets/img/dummy-pic.png')" alt=""
                        class="me-2 rounded-circle" style="width: 30px"
                    />
                    {{ customer.LEGAL_NAME }}
                  </td>
                  <td>{{ customer.EMAIL }}</td>
                  <td>{{ customer.PHONE ? customer.PHONE : 'N/A' }}</td>
                  <td>{{ customer.DATE_ADDED }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="pagination-icon py-2 mt-4">
          <div class="mr-3 cursor-pointer" v-if="page > 1" @click="prevPage">
            <i class="fa fa-angle-double-left " aria-hidden="true" title="Previous page"></i>
          </div>
          <div class="mr-3 page-number d-flex align-items-center justify-content-center" title="Previous page">
            {{ page }}
          </div>
          <div class="cursor-pointer" @click="nextPage">
            <i class="fa fa-angle-double-right pagination-arrow" aria-hidden="true" title="Next page"></i>
          </div>
        </div>
      </template>
    </div>
  </MasterLayout>
</template>

<script>
import MasterLayout from '@/views/Auth/MasterLayout'
import TableLoader from "@/components/TableLoader";

export default {
  components: { MasterLayout, TableLoader },
  watch: {
    page() {
      this.getCustomers()
    }
  },
  data() {
    return {
      start: 0,
      loading: true,
      customers: [],
      page: 1
    }
  },
  mounted() {
    this.getCustomers()
  },
  methods: {
    nextPage() {
      this.start += 21
      this.page = this.page + 1
    },
    prevPage() {
      this.start -= 21
      this.page = this.page - 1
    },
    async getCustomers() {
      const data = { token: localStorage.getItem('token'), start: this.start }
      this.loading = true
      const response = await this.$http.post(`customers`, data)
      this.loading = false
      this.customers = response.data.customers
      this.getNextSet()
      this.getPrevSet()
    },
    async getNextSet() {
      const data = { token: localStorage.getItem('token'), start: this.start + 20 }
      const response = await this.$http.post(`customers`, data)
      this.NextCustomers = response.data.customers
    },
    async getPrevSet() {
      const data = { token: localStorage.getItem('token'), start: this.start - 20 }
      const response = await this.$http.post(`customers`, data)
      this.PrevCustomers = response.data.customers

    },
  }
}
</script>
