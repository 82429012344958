<template>
  <MasterLayout>
    <div v-if="loading" class="container">
      <div class="position-relative" style="width: 200px; height: 60px">
        <div class="card-loader h-100"></div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="position-relative">
            <div class="card-loader" style="height: 230px"></div>
          </div>
          <div class="position-relative mt-4">
            <div class="card-loader" style="height: 200px"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative">
            <div class="card-loader" style="height: 200px"></div>
          </div>
          <div class="position-relative mt-4">
            <div class="card-loader" style="height: 200px"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="details" class="container">
      <div class="d-flex align-items-center mb-4">
        <button
            @click="$router.back()" class="btn btn-outline-secondary rounded-pill d-flex align-items-center justify-content-center"
            style="width: 30px; height: 30px"
        >
          <i class="ri-arrow-left-line fs-6 mr-2"></i>
        </button>
        <div class="d-flex align-items-center ms-3">
          <div>
            <img
                :src="details.FILE_NAME || require('@/assets/img/user.png')" class="rounded-circle overflow-hidden bg-light"
                style="width: 50px; height: 50px" alt="user"
            />
          </div>
          <h3 class="page-title m-0 ms-3">
            {{ details.LEGAL_NAME }}
            <small>
              {{ $filters.formatCurrency(details.REQUEST_PRINCIPAL) }} <i class="mx-1">•</i>
              {{ details.DURATION }} {{ details.LOAN_DURATION }}
            </small>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Summary</h4>
            </div>
            <div class="card-body">
              <swiper :slides-per-view="1.5" :space-between="20">
                <swiper-slide>
                  <div class="stat-widget rounded-lg py-4 px-4 bg-white mb-2 border-success bg-light-green h-100">
                    <h4 class="m-0">{{ $filters.formatCurrency(details.remaining) }}</h4>
                    <p class="m-0">Balance</p>
                    <div class="mt-4 d-flex flex-column align-items-end w-100 justify-content-end">
                      <ul class="list-group list-group-flush w-100 text-end" style="max-width: 200px">
                        <li class="list-group-item bg-transparent px-0">
                          {{ $filters.formatCurrency(summary.collected) }} collected
                        </li>
                        <li class="list-group-item bg-transparent px-0">
                          {{ performance }}
                        </li>
                        <li class="list-group-item bg-transparent px-0">
                          {{ collected }} / {{ schedule.length }} collected
                        </li>
                      </ul>
                      <button @click="tab = 'transaction'" class="btn btn-outline-success btn-sm mt-3">View statement</button>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="stat-widget rounded-lg py-4 px-4 bg-white mb-2 border-danger bg-light-red h-100">
                    <h4 class="m-0">{{ nextDueDate }}</h4>
                    <p class="m-0">Next due date</p>
                    <div class="mt-4 w-100 d-flex flex-column align-items-end justify-content-end">
                      <ul class="list-group list-group-flush w-100 text-end" style="max-width: 250px">
                        <li class="list-group-item bg-transparent px-0">
                          Started on {{ dateStarted }}
                        </li>
                        <li class="list-group-item bg-transparent px-0">
                          Messaged {{ lastMessaged }}
                        </li>
                        <li class="list-group-item bg-transparent px-0">
                          {{ nextReminder }} till next reminder
                        </li>
                      </ul>
                      <button @click="tab = 'schedule'" class="btn btn-outline-danger btn-sm mt-3">View schedule</button>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="stat-widget rounded-lg py-4 px-4 bg-white mb-2 border-warning bg-light-yellow h-100">
                    <h4 class="m-0">{{ summary.tries }}</h4>
                    <p class="m-0">Attempts</p>
                    <div class="mt-4 w-100 d-flex flex-column align-items-end justify-content-end">
                      <ul class="list-group list-group-flush w-100 text-end" style="max-width: 200px">
                        <li class="list-group-item bg-transparent px-0">
                          Last attempted: {{ summary.last_attempted || 'N/A' }}
                        </li>
                        <li class="list-group-item bg-transparent px-0">
                          Last source:
                          <span v-if="summary.last_source === '0'">Backend</span>
                          <img
                              v-else-if="summary.last_source === '1' || summary.last_source === '2'" src="@/assets/images/credit-card.svg"
                              alt="credit card" style="width: 50px"
                          >
                          <img v-else-if="summary.last_source === '3'" src="@/assets/images/nibss-logo.png" alt="nibbs logo" style="width: 50px">
                          <img v-else-if="summary.last_source === '4'" src="@/assets/images/real-estate-agent.svg" alt="agent" style="width: 50px">
                          <span v-else>N/A</span>
                        </li>
                      </ul>
                      <button @click="registerPayment()" class="btn btn-outline-warning btn-sm mt-5">Register payment</button>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="stat-widget rounded-lg py-4 px-4 bg-white mb-2 border-primary bg-light-blue h-100">
                    <h4 class="m-0">{{ $filters.formatCurrency(schedule[0]?.term_repayment) }}</h4>
                    <p class="m-0">{{ details.FREQUENCY }}</p>
                    <div class="mt-4 w-100 d-flex flex-column align-items-end justify-content-end">
                      <ul class="list-group list-group-flush w-100 text-end" style="max-width: 200px">
                        <li class="list-group-item bg-transparent px-0">
                          {{ details.DURATION }} installments
                        </li>
                        <li class="list-group-item bg-transparent px-0">
                          {{ sourcesCount }} sources
                        </li>
                      </ul>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <PortfolioAccounts :accounts="accounts" @register="registerPayment()"/>
        </div>
        <div class="col-md-6">
          <div class="card overflow-visible">
            <div class="card-body overflow-visible">
              <div class="d-flex justify-content-end">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a @click="changeTab('timeline')" :class="{active: tab === 'timeline'}" class="nav-link" href="#">
                      Timeline
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="changeTab('schedule')" :class="{active: tab === 'schedule'}" class="nav-link" href="#">
                      Schedule
                    </a>
                  </li>
                  <li class="nav-item dropdown" :class="{active: tab !== 'timeline' && tab !== 'schedule'}">
                    <a
                        class="nav-link inline-flex align-items-center"
                        data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                    >
                      {{ (tab !== 'timeline' && tab !== 'schedule') ? capitalize(tab) : 'More' }} <i class="ri-arrow-down-s-line ml-2"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a @click="changeTab('payments')" class="dropdown-item" role="button" href="#">Payments</a></li>
                      <li><a @click="changeTab('transaction')" class="dropdown-item" role="button" href="#">Transaction</a></li>
                      <li><a @click="changeTab('sources')" class="dropdown-item" role="button" href="#">Sources</a></li>
                      <li><a @click="changeTab('statistics')" class="dropdown-item" role="button" href="#">Statistics</a></li>
                      <li><a @click="changeTab('messaging')" class="dropdown-item" role="button" href="#">Messaging</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="mt-5">
                <Timeline v-if="tab === 'timeline'"/>
                <Schedule v-if="tab === 'schedule'" :schedule="schedule"/>
                <Transactions v-if="tab === 'transaction'" :transactions="transactions"/>
                <MandateSources v-if="tab === 'sources'" :accounts="accounts"/>
                <Payments :payments="payments" :loading="loadingPayments" v-if="tab === 'payments'"/>
                <PaymentsChart :payments="payments" :loading="loadingPayments" v-if="tab === 'statistics'"/>
                <Messages v-if="tab === 'messaging'" :phone="details.PHONE"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal :visible="modals.payment" @close="modals.payment = false">
      <RegisterPayment :account="current" @done="handleRegisterPaymentDone"/>
    </Modal>
  </MasterLayout>
</template>

<script>
import MasterLayout from "./MasterLayout";
import { Swiper, SwiperSlide } from 'swiper/vue';
import banks from "../../lib/banks";
import Timeline from "../../components/details/Timeline";
import Schedule from "../../components/details/Schedule";
import Transactions from "../../components/details/Transactions";
import MandateSources from "../../components/details/MandateSources";
import { addDays, differenceInDays, formatDistance } from "date-fns";
import Payments from "@/components/details/Payments";
import Modal from "@/components/Modal";
import PortfolioAccounts from "@/components/details/PortfolioAccounts";
import RegisterPayment from "@/components/details/RegisterPayment";
import PaymentsChart from "@/components/details/PaymentsChart";
import Messages from "@/components/details/Messages";

export default {
  name: "PortfolioDetails",
  components: {
    Messages,
    PaymentsChart,
    RegisterPayment,
    PortfolioAccounts,
    Modal,
    Payments,
    MandateSources,
    Transactions,
    Schedule,
    Timeline,
    MasterLayout,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      details: null,
      summary: null,
      loading: false,
      tab: 'timeline',
      modals: {
        payment: false
      },
      payments: [],
      loadingPayments: false
    }
  },
  created() {
    this.getCollectionDetails();
    this.getPayments();
  },
  methods: {
    async getCollectionDetails() {
      const data = {
        token: localStorage.getItem('token'),
        collection_id: this.$route.params.id
      }
      this.loading = true;
      const response = await this.$http.post(`collection/portfolio/details`, data)
      this.details = response.data.data;
      this.summary = response.data.summary;
      this.loading = false;
    },
    getBankName(bank_code) {
      return banks.find(bank => bank.bank_code === bank_code)?.name;
    },
    changeTab(tab) {
      this.tab = tab;
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    registerPayment() {
      this.modals.payment = true;
    },
    handleRegisterPaymentDone() {
      this.modals.payment = false;
    },
    async getPayments() {
      const payload = {
        token: localStorage.getItem('token'),
        collection_id: this.$route.params.id,
      };
      this.loadinPayments = true;
      const res = await this.$http.post(`collection/payments`, payload);
      this.loadinPayments = false;
      this.payments = res.data?.data ?? [];
    }
  },
  computed: {
    accounts() {
      return this.details?.accounts ?? [];
    },
    transactions() {
      return this.details?.chart_account_transactions ?? []
    },
    schedule() {
      return this.details?.schedule ?? []
    },
    sources() {
      return this.details?.GUARANTOR_REQUESTS ?? {};
    },
    sourcesCount() {
      const sources = this.details?.GUARANTOR_REQUESTS ?? {};
      return Object.keys(sources).reduce((acc, key) => {
        if (sources[key]) acc++;
        return acc;
      }, 0);
    },
    collected() {
      return this.schedule.filter(s => +s.how_much_remaining === 0).length;
    },
    nextDueDate() {
      return this.schedule.find(s => +s.how_much_remaining > 0)?.repayment_date;
    },
    dateStarted() {
      return this.schedule[0]?.repayment_date;
    },
    performance() {
      const lastPaymentPaid = this.schedule.find(s => +s.how_much_remaining === 0);
      const nextPayment = this.schedule.find(s => +s.how_much_remaining > 0);
      if (!lastPaymentPaid?.repayment_date) return 'Due';
      if (!nextPayment?.repayment_date) return 'Performing';
      const lastDate = new Date(lastPaymentPaid.repayment_date);
      const nextDate = new Date(nextPayment.repayment_date);
      const today = new Date();
      const lastDiff = differenceInDays(today, lastDate);
      const nextDiff = differenceInDays(today, nextDate);
      if (lastDiff > 0 && nextDiff < 0) return 'Performing';
      else if (nextDiff > 30) return 'Default';
      else return 'Due';
    },
    lastMessaged() {
      const now = new Date();
      const lastMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
      return formatDistance(now, lastMidnight);
    },
    nextReminder() {
      const now = new Date();
      const tomorrow = addDays(now, 1);
      const nextMidnight = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 0, 0, 0);
      return formatDistance(nextMidnight, now);
    },
  },
}
</script>
