import { createRouter, createWebHistory } from 'vue-router'
import PortfolioDetails from "../views/Auth/PortfolioDetails";
import Login from '../views/login';
import Signup from '../views/signup';
import ResetPassword from '../views/resetPassword';
import Otp from '../views/otp';
import Dashboard from '../views/Auth/Dashboard';
import Collections from '../views/Auth/Collections';
import Customers from '../views/Auth/Customers';
import Settings from '../views/Auth/Settings';
import CreateCollection from '../views/Auth/CreateCollection';
import CollectionDetails from '../views/Auth/CollectionDetails';
import CollectionsPortfolio from '../views/Auth/CollectionsPortfolio';
import AddTeamMember from '../views/Auth/AddTeamMember';
import Transfers from '../views/Auth/Transfers';
import NewTransfer from '../views/Auth/CreateTransfer';
import Recovery from "@/views/Auth/Recovery";
import http from "@/lib/http";
import store from "@/store";

const guestRoutes = [
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			guest: true,
		},
	},
	{
		path: "/signup",
		name: "signup",
		component: Signup,
		meta: {
			guest: true,
		},
	},
	{
		path: "/reset-password",
		name: "reset_password",
		component: ResetPassword,
		meta: {
			guest: true,
		},
	},
	{
		path: "/otp",
		name: "otp",
		component: Otp,
		meta: {
			guest: true,
		},
	},
]

const authRoutes = [
	{
		path: "/",
		name: "overview",
		component: Dashboard,
		meta: { authorize: true }
	},
	{
		path: "/requests",
		name: "requests",
		component: Collections,
		meta: { authorize: true }
	},
	{
		path: "/requests/:id",
		name: "request-details",
		component: CollectionDetails,
		meta: { authorize: true }
	},
	{
		path: "/new-transfer",
		name: "NewTransfer",
		component: NewTransfer,
		meta: { authorize: true }
	},

	{
		path: "/Transfers",
		name: "transfers",
		component: Transfers,
		meta: { authorize: true }
	},

	{
		path: "/settings",
		name: "settings",
		component: Settings,
		meta: { authorize: true }
	},
	{
		path: "/create-collection",
		name: "create_collection",
		component: CreateCollection,
		meta: { authorize: true }
	},
	{
		path: "/customers",
		name: "customers",
		component: Customers,
		meta: { authorize: true }
	},
	{
		path: "/recovery",
		name: "recovery",
		component: Recovery,
		meta: { authorize: true }
	},
	{
		path: "/portfolio",
		name: "portfolio",
		component: CollectionsPortfolio,
		meta: { authorize: true }
	},
	{
		path: "/portfolio/:id",
		name: "portfolio-details",
		component: PortfolioDetails,
		meta: { authorize: true }
	},
	{
		path: "/teams/add-member",
		name: "add-team-member",
		component: AddTeamMember,
		meta: { authorize: true }
	},
]

let routes = [...guestRoutes, ...authRoutes];

const router = createRouter({ history: createWebHistory(), routes })

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((rec) => rec.meta.authorize)) {
		const token = localStorage.getItem('token');
		const from = to.fullPath;
		if (!token) return next({ path: '/login', query: { from } });
		if (!store.state.user) {
			try {
				const { data: { profile, api_key } } = await http.post('/profile', { token });
				store.commit('setUser', { ...profile, api_key });
			} catch (e) {
				return next({ path: '/login', query: { from } });
			}
		}
		return next();
	} else if (to.matched.some((rec) => rec.meta.guest)) {
		return next();
	} else {
		next();
	}
});


export default router
