<template>
    <div class="authincation section-padding">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-xl-4 col-md-5">
                    <div class="mini-logo text-center my-3">
                         <img src="https://creditclan.com/pub/images/logo-small.png" alt="" width="180">
                        <h4 class="card-title mt-5">Reset Password</h4>
                    </div>
                    <div class="auth-form card">
                        <div class="card-body">
                            <form action="#" class="row g-3">
                                <div class="col-12">
                                    <label class="form-label">Email</label>

                                    <input type="text"  v-model="email" class="form-control" placeholder="***********">
                                </div>
                                <div class="text-center mt-4">
                                    <!-- <button type="submit" class="btn btn-primary btn-block">Submit</button> -->
                                    <button class="btn btn-primary btn-block" @click="savePaystackKeys" :disabled="isPaystackButtonDisabled"
                                    :style="[
                                        isPaystackButtonDisabled
                                        ? { cursor: 'not-allowed' }
                                        : null,
                                    ]"
                                    >Submit</button>
                      
                                    <pulse-loader size="15px"  v-if="isPaystackButtonDisabled"></pulse-loader>
                                </div>
                            </form>
                            <div class="new-account mt-3">
                                <p>Back to ?  
                                <router-link  :to="{name: 'login'}" class="text-primary">
                                  Login
                                </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export default {
  components: {
    PulseLoader,
  },
  data() {
    return {
      email: null,
      isPaystackButtonDisabled: false,
    };
  },
  methods: {
    savePaystackKeys(e) {
      e.preventDefault();
      if (!this.email) {
        createToast("Enter  email you registered with", {
          type: "danger",
          timeout: 5000,
        });
        return;
      }

      const payload = {
        email: this.email
      };
      this.isPaystackButtonDisabled = true;
      this.$http.post(`api/v2/loan/forgot`, payload).then((res) => {
        this.isPaystackButtonDisabled = false;
        createToast("An email has been sent!", {
          type: "success",
        });
      });
    },
  },
};
</script>
