import { createApp } from 'vue'
import store from "./store"
import App from './App.vue'
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import './assets/css/global.css';
/**********  CHARTS **********************/
import VueApexCharts from 'vue3-apexcharts'
import HighchartsVue from "highcharts-vue";
import VueHighcharts from "vue-highcharts";
import Highcharts from "highcharts";

import 'swiper/swiper.scss';
import { formatCurrency } from "./lib/utils";
import http from "@/lib/http";
import { formatDistance } from "date-fns";
import { Field, Form } from "vee-validate";

const VueTelInputOptions = {
	mode: "international",
	onlyCountries: ['NG']
}

const app = createApp(App)

app.config.globalProperties.$filters = {
	formatCurrency(value) {
		return formatCurrency(value);
	},
	roundToDp(number, places) {
		return (+number).toFixed(places);
	},
	formatDistance(date) {
		return formatDistance(new Date(date), new Date(), { addSuffix: false });
	}
}

app.use(store)

app.component('VeeForm', Form);

app.component('Field', Field);

app.use(VueTelInput, VueTelInputOptions)

app.use(VueAxios, axios)

app.config.globalProperties.$http = http;

app.use(VueSweetalert2);

app.use(router)

app.use(HighchartsVue)

app.use(VueHighcharts, { Highcharts })

app.use(VueApexCharts);

app.mount('#app')

