<template>
  <div v-if="loading" class="position-relative mt-5 py-5 text-center fst-italic d-flex align-items-center justify-content-center">
    <div class="spinner-border spinner-border-sm me-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    Loading data..
  </div>
  <div v-else-if="!payments.length" class="text-center text-muted mt-5 py-5">No data to show</div>
  <div v-else>
    <div class="d-flex align-items-center justify-content-center mb-4">
      <select v-model="mode" class="form-select form-select-sm ms-3" style="width: 200px">
        <option value="source">Source</option>
        <option value="account">Account</option>
      </select>
    </div>
    <apexchart type="donut" :options="statisticsChart" :series="chartData"></apexchart>
  </div>
</template>

<script>
export default {
  name: "PaymentsChart",
  props: {
    payments: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    mode: 'source',
    statisticsChart: {
      chart: {
        type: 'donut',
      },
      labels: ['Backend', 'Direct debit', 'Card', 'Agent'],
      legend: {
        position: 'bottom',
        onItemClick: {
          toggleDataSeries: true
        },
        fontFamily: 'Varela Round'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
    },
    chartData: []
  }),
  created() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      if (this.mode === 'source') this.filterBySource();
      if (this.mode === 'account') this.filterByAccount();
      else this.filterBySource();
    },
    filterBySource() {
      const backend = this.payments.reduce((acc, p) => {
        if (p.source === '0') acc += +p.amount_paid;
        return acc;
      }, 0);
      const dd = this.payments.reduce((acc, p) => {
        if (p.source === '3') acc += +p.amount_paid;
        return acc;
      }, 0);
      const card = this.payments.reduce((acc, p) => {
        if (p.source.match(/[23]/g)) acc += +p.amount_paid;
        return acc;
      }, 0);
      const agent = this.payments.reduce((acc, p) => {
        if (p.source === '4') acc += +p.amount_paid;
        return acc;
      }, 0);
      this.statisticsChart.labels = ['Backend', 'Direct debit', 'Card', 'Agent'];
      this.chartData = [+backend ?? 0, +dd ?? 0, +card ?? 0, +agent ?? 0];
    },
    filterByAccount() {
      const accounts = this.payments.reduce((acc, payment) => {
        const { account_card_id } = payment;
        if (account_card_id && !acc.includes(account_card_id)) acc.push(account_card_id)
        return acc;
      }, []);
      let data = [];
      if (accounts.length) {
        data = accounts.map(account => {
          return this.payments.filter(p => p.account_card_id === account).reduce((acc, p) => {
            acc += +p.amount_paid;
            return acc;
          }, 0);
        })
      }
      this.statisticsChart.labels = accounts;
      this.chartData = data;
    },
  },
  watch: {
    mode() {
      this.updateChart();
    }
  }
}
</script>
