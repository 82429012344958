<template>
  <div class="sellup-wrapper">
    <div class="sellup-container left d-flex flex-column align-items-center justify-content-center">
      <div class="text-center mt-5">
        <img class=" sellup-img" src="https://omni.creditclan.com/assets/images/analytics.svg"/>
      </div>
      <br><br><br><br>
      <div class="text-center">
        <h5 class="text-white fs-5">Welcome! sign in to your dashboard <br> to manage your collections for your business</h5>
      </div>
    </div>
    <div class="sellup-container right bg-white d-flex flex-column">
      <div class="signup-form py-5 my-auto">
        <img src="@/assets/images/cc-logo-md.png" style="width: 60px"/>
        <h4 class="card-title mt-5 fs-4">Login to your account</h4>
        <form method="post" name="myform" class="signin_validate row g-3 mt-4" action="https://Creditclan-html.vercel.app/otp-2.html">
          <div class="col-12">
            <label class="form-label">Email</label>
            <input type="email" v-model="email" class="form-control" placeholder="hello@example.com">
            <span class="error-message"></span>
          </div>
          <div class="col-12">
            <div class="position-relative">
              <label class="form-label">Password</label>
              <input type="password" v-model="password" class="form-control" placeholder="Password" name="password">
              <span class="error-message"></span>
            </div>
          </div>
          <div class="col-6 mt-4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Remember me</label>
            </div>
          </div>
          <div class="col-6 mt-4 text-end">
            <router-link :to="{name: 'reset_password'}" class="text-primary">
              Forgot Password?
            </router-link>
          </div>
          <div class="mt-4">
            <button type="submit" class="btn btn-primary mt-2 d-flex align-items-center" @click="login" :disabled="loading">
              <span v-if="loading" class="spinner-grow spinner-grow-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              Signin
            </button>
          </div>
        </form>
        <p class="mt-3 mb-0">
          Don't have an account?
          <router-link :to="{name: 'signup'}" class="text-primary">Signup</router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

export default {
  data() {
    return {
      email: null,
      password: null,
      loading: false,
    }
  },
  methods: {
    async login(e) {
      e.preventDefault()
      if (!this.email || !this.password) return createToast('Please enter your email and address', { type: 'danger' })
      let data = {
        email: this.email,
        password: this.password,
        grant_type: "password",
      }
      this.loading = true
      try {
        const response = await this.$http.post(`/authenticate`, data)
        this.loading = false;
        if (response.data.checked === false) return createToast(response.data.error, { type: 'danger', timeout: 5000 })
        if (response.error) return createToast(response.error, { type: 'danger' })
        localStorage.setItem('token', response.data.token);
        await this.$router.push(`${ this.$route.query.from || '/' }`);
      } catch (e) {
        console.log({ e });
        this.loading = false;
      }
    },
  }
}
</script>
