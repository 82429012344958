<template>
  <div class="my-auto p-5">
    <h4 class="text-primary-dark mb-1">Charge card</h4>
    <p class="text-muted">How much do you want to charge?</p>

    <div class="mt-5">
      <label for="amount" class="form-label">Enter amount</label>
      <input type="number" v-model="amount" class="form-control" id="amount">
    </div>

    <button @click="chargeCard()" type="submit" class="btn btn-primary btn-sm mt-5 d-flex align-items-center" :disabled="loading || !amount">
      <span v-if="loading" class="spinner-grow spinner-grow-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </span>
      Charge card
    </button>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";

export default {
  name: "ChargeCard",
  props: {
    account: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    amount: '',
    loading: false
  }),
  methods: {
    async chargeCard() {
      if (!this.amount) return;
      const payload = {
        collection_id: this.$route.params.id,
        account_card_id: this.account.account_card_id,
        amount: this.amount
      };
      this.loading = this.account.account_card_id;
      try {
        const res = await this.$http.post(`/collection/charge/card`, payload);
        if (!res.data.status) createToast(res.data?.message || 'An error occurred, please try again', {
          type: 'danger', timeout: 5000
        }); else {
          createToast('Card charged', { type: 'success', timeout: 5000 });
          this.$emit('done');
        }
      } catch (e) {
        createToast(e?.response?.data?.message || 'An error occurred, please try again', { type: 'danger', timeout: 5000 });
      }
      this.loading = '';
    }
  }
}
</script>
