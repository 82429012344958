<template>
  <div class="sidebar">
    <div class="brand-logo"><a class="full-logo" href="#"><img src="@/assets/images/logo-home.png" alt=""></a></div>
    <div class="menu">
      <ul>
        <li :class="{ 'active-nav': $route.name === 'overview'}">
          <router-link to="/">
            <i class="ri-dashboard-2-line"></i>
            <span class="text-white">Overview</span>
          </router-link>
        </li>
        <li :class="{ 'active-nav': $route.name === 'requests'}">
          <router-link to="/requests">
            <i class="ri-file-list-line"></i>
            <span class="text-white">Collect</span>
          </router-link>
        </li>
        <li :class="{ 'active-nav': $route.name === 'portfolio'}">
          <router-link to="/portfolio">
            <i class="ri-briefcase-line"></i>
            <span class="text-white">Portfolio</span>
          </router-link>
        </li>
        <li :class="{ 'active-nav': $route.name === 'recovery'}">
          <router-link to="/recovery">
            <i class="ri-device-recover-line"></i>
            <span class="text-white">Recover</span>
          </router-link>
        </li>
        <li :class="{ 'active-nav': $route.name === 'transfers'}">
          <router-link to="/transfers">
            <i class="ri-file-transfer-line"></i>
            <span class="text-white">Transfer</span>
          </router-link>
        </li>
        <li :class="{ 'active-nav': $route.name === 'settings'}">
          <router-link to="/settings">
            <i class="ri-settings-line"></i>
            <span class="text-white">Settings</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      activeRoute: null
    }
  },
  beforeMount() {
    this.activeRoute = this.$route.name;
  },
}
</script>
