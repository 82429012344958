<template>
  <div class="p-5">
    <h4 class="card-title">Connection details</h4>
    <ul v-if="details" class="mt-4 list-group">
      <li class="list-group-item">
        <span>Name</span>
        <span>{{ details.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ConnectionDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      details: null
    }
  },
  created() {
    this.getConnectionDetails()
  },
  methods: {
    async getConnectionDetails() {
      const res = await this.$http.post('https://view.bankstatement.ai/index.php/whatsapp/getMetadata', { transaction_id: this.id });
      this.currentConnectionDetails = res.data.data;
    },
  }
}
</script>
