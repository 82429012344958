<template>
  <div class="invoice-table">
    <div class="table-responsive">
      <div v-if="!transactions || !transactions.length" class="text-center text-muted py-5">No transactions recorded</div>
      <div v-if="transactions && transactions.length > 0">
        <table class="table table-sm">
          <thead>
          <tr>
            <th>Date added</th>
            <th>Narration</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Balance</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(transaction,i) in transactions" :key="i">
            <td>{{ transaction.DATE_ADDED }}</td>
            <td>{{ transaction.NARRATION }}</td>
            <td>{{ $filters.formatCurrency(transaction.DEBIT) }}</td>
            <td>{{ $filters.formatCurrency(transaction.CREDIT) }}</td>
            <td>{{ $filters.formatCurrency(transaction.BALANCE) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Transactions",
  props: {
    transactions: {
      type: Array,
      required: true
    }
  }
}
</script>
