<template>
  <MasterLayout>
    <div class="container">
      <h3 class="page-title">Add team member</h3>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <form class="invoice-form">
                <form>
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="mb-3"><label class="form-label">Customer Name</label>
                        <input type="email" class="form-control" v-model="full_name" placeholder="Ebenezer Igbinoba">
                      </div>
                      <div class="mb-3"><label class="form-label">Customer Phone</label>
                        <input type="email" class="form-control" v-model="phone" placeholder="08163763753 ">
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="mb-3"><label class="form-label">Customer Email</label>
                        <input type="email" class="form-control" v-model="email" placeholder="eben@gmail.com"></div>
                    </div>
                  </div>
                  <div class="btn-group mt-2">
                    <button type="submit" class="btn btn-primary mt-2 d-flex align-items-center" @click="AddTeamMember" :disabled="isButtonDisabled">
                      <span v-if="isButtonDisabled" class="spinner-grow spinner-grow-sm me-2" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </span>
                      Add
                    </button>
                  </div>
                </form>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MasterLayout>
</template>
<script>

import MasterLayout from '@/views/Auth/MasterLayout'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  components: {
    MasterLayout,
    PulseLoader
  },
  data() {
    return {
      isButtonDisabled: false,
      customer_id: "",
      full_name: null,
      email: null,
      phone: null
    }
  },
  mounted() {

  },
  methods: {
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
    addTeamMember(e) {
      e.preventDefault();
      if (!this.full_name) {
        createToast('Full name is required ', {
          type: 'danger'
        })
        return
      }
      if (!this.phone) {
        createToast('Phone name is required', {
          type: 'danger'
        })
        return
      } else {
        if (this.phone.length !== 11) {
          createToast('Phone should contain 11 digits!', {
            type: 'danger'
          })
          return
        }
      }
      if (!this.email) {
        createToast('Email is required', {
          type: 'danger',
          timeout: 5000
        })
        return
      } else {
        if (!this.validateEmail(this.email)) {
          createToast('Invalid email format', {
            type: 'danger',
            timeout: 5000
          })
          return
        }
      }
      const payload = {
        full_name: this.full_name,
        email: this.email,
        phone: this.phone
      }
      const data = {
        token: localStorage.getItem('token'),
        data: payload
      }
      this.isButtonDisabled = true
      this.$http.post(`team/onboard`, data)
          .then(() => {
            this.isButtonDisabled = false
            this.showLoader = false
            this.$router.push({ name: 'settings' })
            createToast('Team member add  successfully', {
              type: 'success'
            })
          })
    }
  }
}
</script>
