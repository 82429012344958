<template>
  <div class="mb-4 d-flex align-items-end">
    <textarea
        v-model="message" :disabled="loading" type="search" class="form-control form-control-lg rounded-lg"
        placeholder="Enter your message here" style="height: 45px"
    ></textarea>
    <button
        @click="sendMessage()" :disabled="!message || loading" style="width: 40px; height: 40px"
        class="btn btn-outline-primary rounded-pill d-flex align-items-center justify-content-center ms-2"
    >
      <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </span>
      <i v-else class="ri-send-plane-line fs-6 mr-2"></i>
    </button>
  </div>
  <div v-if="!ready">
    <div class="position-relative" style="height: 50px">
      <div class="card-loader h-100"></div>
    </div>
    <div class="position-relative mt-3" style="height: 50px">
      <div class="card-loader h-100"></div>
    </div>
  </div>
  <ul v-else>
    <li v-for="message in messages" class="card mb-2" style="padding: .7rem 1rem">
      {{ message.body }}
      <small class="d-flex text-muted">{{ $filters.formatDistance(message.created_date) }}</small>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Messages",
  props: {
    phone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: '',
      messages: [],
      loading: false,
      ready: false,
    }
  },
  created() {
    this.getMessagesLog();
  },
  methods: {
    async sendMessage() {
      if (!this.message) return;
      this.loading = true;
      const phone = this.phone.replace('0', '234');
      await this.$http.post('https://whatsapp.creditclan.com/whatsapp2', { message: this.message, phone });
      await this.saveMessage();
      await this.getMessagesLog();
      this.message = '';
      this.loading = false;
    },
    async getMessagesLog() {
      const res = await this.$http.post('/collection/messages', { collection_id: this.$route.params.id });
      this.messages = res?.data?.data ?? [];
      this.ready = true;
    },
    async saveMessage() {
      await this.$http.post('/collection/send_message', {
        collection_id: this.$route.params.id,
        message: this.message
      });
    }
  }
}
</script>
